/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import {
  IAddCustomPropertyProduct,
  ICustomPropertyProduct,
  ICustomPropertyPO,
} from "../../model";
import { Field, Formik } from "formik";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { AutoComplete, Form, Spin } from "antd";
import FieldName from "../../../../components/FieldName";
import { debounce } from "lodash";
import {
  FetchProductPOs,
  SearchProducts,
} from "../../../../redux/actions/products.action";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { RootState } from "../../../../redux/reducers";
import { ApiDropdownModel } from "../../../../model/base.model";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";
import { ReactComponent as QuicstockDot } from "../../../../assets/svgs/quicstock-dot.svg";
import { ReactComponent as QuicstockChevronDown } from "../../../../assets/svgs/quicstock-chevron-down.svg";

const AddCustomPropertyProduct: FC<IAddCustomPropertyProduct> = ({
  setItem,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const [productId, setProductId] = useState<string>("");
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );
  const productPos = useSelector(
    (state: RootState) => state.products.productPos
  );
  const [values] = useState<ICustomPropertyProduct>({
    productId: "",
    product: null,
    isGlobal: true,
    poIds: [],
  });
  const [property, setProperty] = useState<ICustomPropertyProduct>({
    productId: "",
    isGlobal: true,
    poIds: [],
    cPos: [],
  });

  const [form] = Form.useForm();

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  useEffect(() => {
    setItem(property);
  }, [property]);

  useEffect(() => {
    if (productId) {
      dispatch(FetchProductPOs(productId));
    }
  }, [productId]);

  useEffect(() => {
    const ppo: ICustomPropertyPO[] = productPos.map((p, index) => {
      return {
        id: index + 1,
        po_number: p?.po_number ?? "0",
        quantity: p?.quantity ?? 0,
        is_checked: false,
      };
    });
    setProperty({ ...property, cPos: ppo });
  }, [productPos]);

  return (
    <div className="w-full">
      <h2 className="text-sm-headline mb-2">Add product(s)</h2>
      <p className="text-sm-regular text-gray-300 mb-6">
        After picking all the products, you can proceed to create custom
        property. You can also expand the products to pick a purchase order.
      </p>
      <Formik
        className="w-full"
        initialValues={values}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ values, errors, touched }) => (
          <Form form={form}>
            <div className={`w-full`}>
              <div className={"mb-5"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Item"} required />
                  <Form.Item
                    name={"productId"}
                    help={
                      touched.productId && errors.productId
                        ? errors.productId
                        : ""
                    }
                    validateStatus={
                      touched.productId && errors.productId
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <AutoComplete
                          options={options}
                          onSearch={handleSearch}
                          onSelect={(value, option) => {
                            setFieldValue("productId", value);
                            setSelectedProductName(option.name ?? "");
                            setFieldValue(
                              "product",
                              productSearchResult.find((p) => p.id === value)
                            );
                            setProperty({ ...property, productId: value });
                            setProductId(value);
                          }}
                          onChange={(value) => {
                            setSelectedProductName(value);
                            setFieldValue("productId", "");
                            setFieldValue("product", null);
                            setProperty({ ...property, productId: "" });
                            if (!value) {
                              setOptions([]);
                            }
                          }}
                          value={selectedProductName || values.productId}
                          notFoundContent={
                            isLoading ? <Spin size="small" /> : null
                          }
                          allowClear
                          style={{ width: "100%", height: "48px" }}
                        >
                          <input
                            type="text"
                            className="form-field_input_2"
                            placeholder="Search for item"
                          />
                        </AutoComplete>
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              {values.product && (
                <div className="mb-5 w-full">
                  <div className="w-full border p-3 border-dark-600">
                    <div className="w-full xl:flex grid justify-between items-center">
                      <div className="flex items-center gap-x-2">
                        <div className="h-[42px] w-[42px] rounded-ful">
                          <img
                            src={
                              values?.product?.image_url
                                ? values?.product?.image_url
                                : QuicstockNoImage
                            }
                            alt={
                              values?.product?.image_url
                                ? values?.product?.name
                                : "QuicstockNoImage"
                            }
                            className="h-full w-full"
                          />
                        </div>
                        <p className="font-medium text-sm-headline">
                          {values?.product?.name}
                        </p>
                      </div>
                      <div className="flex items-center gap-x-2 xl:mt-0 mt-4">
                        <p>Purchase orders</p>
                        <div className="mx-2">
                          <QuicstockDot className="product-properties-separator" />
                        </div>
                        <p>{productPos.length}</p>
                        <QuicstockChevronDown />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { AddCustomPropertyProduct };
