import React, { FC } from "react";
import "../../styles.scss";
import { IAllCustomProperties } from "../../model";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { CPModel } from "../../../../model/product.model";
import Loader from "../../../../components/Loader";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";

const AllCustomProperties: FC<IAllCustomProperties> = ({ view }) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const cp = useSelector((state: RootState) => state.products.cp);

  return (
    <div className="w-full bg-white app-table pb-4 overflow-y-auto mt-10">
      <Loader isLoading={isLoading} />
      <div className="border border-dark-200 rounded-[10px] xl:py-10 sm:py-5 py-1 xl:px-10 sm:px-5 px-1 w-full flex flex-col gap-y-8">
        {cp?.map((c) => <CProperty customProperty={c} view={view} />)}
      </div>
    </div>
  );
};

const CProperty: FC<{
  customProperty: CPModel;
  view: (cp: CPModel) => void;
}> = ({ customProperty, view }) => {
  return (
    <button onClick={() => view(customProperty)} className="flex justify-between text-sm-regular">
      <div className="flex items-center gap-x-2">
        <img
          src={QuicstockNoImage}
          alt={"QuicstockNoImage"}
          className={"xl:h-[36px] h-[28px] rounded-full"}
        />
        <p>{customProperty.name}</p>
      </div>
      <p>{customProperty.custom_property_count} properties</p>
    </button>
  );
};

export { AllCustomProperties };
