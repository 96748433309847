import React, { useEffect, useState } from "react";
import "../styles.scss";
import PageHeader from "../../../components/PageHeader";
import { LeftHeaderType } from "../../../components/enum";
import {
  ADMIN_SALES_DATA,
  INVENTORY_MANAGER_SALES_DATA,
  SUPER_ADMIN_SALES_DATA,
} from "../../../utils/misc/database";
import {
  QuickLinks,
  RecentStockMovement,
  RecentUserLogs,
  SalesCard,
  SalesReport,
  SlowMovingProducts,
  TopProducts,
  ToReorder,
  ToRestock,
} from "./components";

const Overview = () => {
  const [user, setUser] = useState<{ name?: string; role?: string }>({});
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    console.log(search);
  }, [search]);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("quicstock_app_user") ?? "");
    setUser(_user);
  }, []);

  return (
    <div className="w-full h-full">
      <PageHeader
        component={
          <p className="textx-sm-headline font-medium">Welcome back, Poms</p>
        }
        leftHeaderType={LeftHeaderType.COMPONENT}
        showSearchField={true}
        placeholder={"Search"}
        getSearchValue={setSearch}
      />

      <div className="w-full mt-4">
        <p className="text-sm-headline font-medium">Overview</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
          {(user?.role === "root"
            ? SUPER_ADMIN_SALES_DATA
            : user?.role === "admin"
              ? ADMIN_SALES_DATA
              : INVENTORY_MANAGER_SALES_DATA
          ).map((sd) => (
            <SalesCard key={sd.label} sales={sd} />
          ))}
        </div>
        <div className="grid grid-cols-12 gap-4 mt-6">
          {user?.role === "inventory_manager" ? (
            <div className="col-span-12">
              <TopProducts />
            </div>
          ) : (
            <>
              <div className="col-span-8">
                {user?.role === "root" ? <SalesReport /> : <TopProducts />}
              </div>
              <div className="col-span-4">
                <QuickLinks />
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 mt-6">
          {user?.role !== "inventory_manager" && <SlowMovingProducts />}
          {user?.role !== "inventory_manager" && <RecentUserLogs />}
          {user?.role !== "inventory_manager" && <RecentStockMovement />}
          {user?.role === "inventory_manager" && <ToReorder />}
          <ToRestock />
        </div>
      </div>
    </div>
  );
};

export default Overview;
