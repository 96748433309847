import { ReportState } from "../../views/Main/enum";
import {
  IRecentStockMovement,
  IRecentUserLog,
  IReport,
  IReportRandomColor,
  IReportType,
  ISalesData,
  IUserLog,
} from "../../views/Main/model";

export const USERS_LOGS: IUserLog[] = [
  {
    id: 1,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 2,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 3,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 4,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 5,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 6,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 7,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 8,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 9,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 10,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 11,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 12,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 13,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 14,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 15,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 16,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 17,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 18,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 19,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 20,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
];

export const REPORT_TYPES: IReportType[] = [
  { id: 1, name: "All" },
  { id: 2, name: "Business Overview" },
  { id: 3, name: "Sales" },
  { id: 4, name: "Receivables" },
  { id: 5, name: "User logs" },
];

export const REPORTS: IReport[] = [
  { id: 1, name: "Restock report", type: 1 },
  { id: 2, name: "Stockout report", type: 2 },
  { id: 3, name: "Stock value report", type: 3 },
  { id: 4, name: "Gross profit report", type: 4 },
];

export const REPORT_STATES: IReportRandomColor[] = [
  {
    state: ReportState.BLUE,
    color: "blue-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#EDF3FF] flex items-center justify-center",
  },
  {
    state: ReportState.PINK,
    color: "pink-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#FFEDFD] flex items-center justify-center",
  },
  {
    state: ReportState.ORANGE,
    color: "orange-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#FFF2EE] flex items-center justify-center",
  },
  {
    state: ReportState.GREEN,
    color: "green-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#EDFFF3] flex items-center justify-center",
  },
];

export const SUPER_ADMIN_SALES_DATA: ISalesData[] = [
  {
    label: "Store value",
    value: "N23,908",
    rate: 20,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Total  Sales",
    value: "112,097",
    rate: 10,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Out of stock",
    value: "345",
    rate: -10,
    report: [6, 3, 10, 20, 12, 6],
    reportFocusColor: "#FF6666",
  },
  {
    label: "Deliveries due",
    value: "12",
    rate: 8,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
];

export const ADMIN_SALES_DATA: ISalesData[] = [
  {
    label: "Total  Sales",
    value: "112,097",
    rate: 10,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Deliveries Due",
    value: "N23,908",
    rate: 20,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Out of stock",
    value: "345",
    rate: -10,
    report: [6, 3, 10, 20, 12, 6],
    reportFocusColor: "#FF6666",
  },
  {
    label: "Low Stock",
    value: "12",
    rate: 8,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
];

export const INVENTORY_MANAGER_SALES_DATA: ISalesData[] = [
  {
    label: "Average supplier reliability",
    value: "112,097",
    rate: 10,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Deliveries Due",
    value: "N23,908",
    rate: 20,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
  {
    label: "Out of stock",
    value: "345",
    rate: -10,
    report: [6, 3, 10, 20, 12, 6],
    reportFocusColor: "#FF6666",
  },
  {
    label: "Low Stock",
    value: "12",
    rate: 8,
    report: [8, 12, 18, 30, 15, 10],
    reportFocusColor: "#5A3FFF",
  },
];

export const RECENT_USER_LOGS: IRecentUserLog[] = [
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
  {
    userName: "Admin - John",
    action: "Updated Inventory",
    details: "Marked iPhone 15 Pro as sold",
  },
];

export const RECENT_STOCK_MOVEMENT: IRecentStockMovement[] = [
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
  {
    productName: "Nike Air Max",
    action: "Sold",
    quantity: "-2",
    performedBy: "Sales Staff A",
  },
];
