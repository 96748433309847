import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ISalesCard } from "../../model";
import { ReactComponent as QuicstockArrowUp } from "../../../../assets/svgs/quicstock-arrow-up.svg";
import { ReactComponent as QuicstockArrowDown } from "../../../../assets/svgs/quicstock-arrow-down.svg";
import ReactECharts from "echarts-for-react";

const SalesCard: FC<ISalesCard> = ({ sales }) => {
  const [chartOption, setChartOption] = useState<any>({});
  const [isUp, setIsUp] = useState<boolean>(false);

  useEffect(() => {
    setIsUp(sales.rate >= 0);

    setChartOption({
      xAxis: {
        type: "category",
        show: false,
      },
      yAxis: {
        show: false,
      },
      grid: {
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
        containLabel: false,
      },
      series: [
        {
          type: "bar",
          data: sales.report,
          itemStyle: {
            color: (params: { dataIndex: number }) =>
              params.dataIndex === 3 ? sales.reportFocusColor : "#C2D5FF",
            borderRadius: [4, 4, 0, 0],
          },
          barWidth: 8,
        },
      ],
    });
  }, [sales]);

  return (
    <div className="px-4 py-3 border-[1px] border-[#EAECF0] rounded-[10px]">
      <p className="text-x-small font-medium">{sales.label}</p>
      <p className="text-x-sm-headline font-bold mt-2">{sales.value}</p>
      <div className="w-full flex mt-2 justify-between items-end">
        <div className="flex items-center gap-x-2">
          <div
            className={`border-[0.5px] px-2 p-1 rounded-[30px] flex items-center gap-x-[2px] ${
              isUp
                ? "bg-[#EBFFF5] border-[#009C51] text-[#009C51]"
                : "bg-[#FFEBEB] border-[#C20000] text-[#C20000]"
            }`}
          >
            {isUp ? <QuicstockArrowUp /> : <QuicstockArrowDown />}
            <p className="text-[9px]">{Math.abs(sales.rate)}%</p>
          </div>
          <p className="text-xx-small text-[#475467] font-medium">
            Vs last month
          </p>
        </div>
        <div className="w-20 h-12">
          <ReactECharts
            option={chartOption}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export { SalesCard };
