import React, { FC, useState } from "react";
import "../../styles.scss";
import { Select, Table } from "antd";
import { RECENT_STOCK_MOVEMENT } from "../../../../utils/misc/database";

const RecentStockMovement: FC = () => {
  const [option, setOption] = useState<string>("");

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
    },
    {
      title: "Performed By",
      dataIndex: "performedBy",
      key: "performedBy",
      ellipsis: true,
    },
  ];

  const data =
    RECENT_STOCK_MOVEMENT?.length > 0 &&
    RECENT_STOCK_MOVEMENT?.map((rsm, index) => {
      return {
        key: index,
        productName: rsm.productName ?? "---",
        action: rsm.action ?? "---",
        quantity: rsm.quantity ?? "---",
        performedBy: rsm.performedBy ?? "---",
      };
    });

  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-regular font-medium">Recent Stock Movement</p>
        <Select
          placeholder={"Select option"}
          value={option || null}
          className={"overview-field !w-fit"}
          onChange={(e: any) => {
            setOption(e);
          }}
          options={[{ value: "ALL", label: "All" }]}
        />
      </div>
      <div className="w-full mt-4">
        <Table
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={false}
        />
      </div>
    </div>
  );
};

export { RecentStockMovement };
