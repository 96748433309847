import {
  CPModel,
  ProductDetailsModel,
  ProductItemModel,
  ProductResponseItemModel,
  UnitItemModel,
} from "../../model/product.model";
import { Types } from "../actions/products.action";

interface ProductState {
  isProductActionLoading: boolean;
  isProductPosLoading: boolean;
  isRestockLocationLoading: boolean;
  isProductUnitLoading: boolean;
  message?: string;
  products: ProductResponseItemModel;
  productSearchResult: ProductItemModel[];
  product: ProductDetailsModel;
  cp: CPModel[];
  cpDetails: CPModel[];
  productPos: ProductItemModel[];
  restockLocations: ProductItemModel[];
  uoms: UnitItemModel[];
  uom: UnitItemModel[];
}

interface ProductAction {
  type: string;
  message?: string;
  products: ProductResponseItemModel;
  productSearchResult: ProductItemModel[];
  product: ProductDetailsModel;
  cp: CPModel[];
  cpDetails: CPModel[];
  productPos: ProductItemModel[];
  restockLocations: ProductItemModel[];
  uoms: UnitItemModel[];
  uom: UnitItemModel[];
}

const INITIAL_STATE: ProductState = {
  isProductActionLoading: false,
  isProductPosLoading: false,
  isRestockLocationLoading: false,
  isProductUnitLoading: false,
  products: null as any,
  productSearchResult: [],
  product: null as any,
  cp: [],
  cpDetails: null as any,
  productPos: [],
  restockLocations: [],
  uoms: [],
  uom: [],
};

export const productReducer = (
  state = INITIAL_STATE,
  action: ProductAction
) => {
  switch (action.type) {
    // STARTS BEGINGS
    case Types.CREATE_PRODUCT_START:
    case Types.BULK_PRODUCT_UPLOAD_START:
    case Types.FETCH_PRODUCTS_START:
    case Types.SEARCH_PRODUCTS_START:
    case Types.FETCH_PRODUCT_START:
    case Types.SET_PRICE_START:
    case Types.CREATE_CUSTOM_PROPERTY_START:
    case Types.FETCH_CUSTOM_PROPERTIES_START:
    case Types.FETCH_CUSTOM_PROPERTY_START:
    case Types.UPDATE_CUSTOM_PROPERTY_START:
    case Types.DELETE_CUSTOM_PROPERTY_START:
    case Types.CREATE_UNIT_START:
    case Types.FETCH_UNITS_START:
    case Types.UPDATE_UNIT_START:
    case Types.DELETE_UNIT_START:
    case Types.UPDATE_PRODUCT_START:
      return { ...state, isProductActionLoading: true };
    case Types.FETCH_PRODUCT_POS_START:
      return { ...state, isProductPosLoading: true };
    case Types.FETCH_RESTOCK_LOCATIONS_START:
      return { ...state, isRestockLocationLoading: true };
    case Types.FETCH_PRODUCT_UNITS_START:
      return { ...state, isProductUnitLoading: true };
    // STARTS ENDS
    // SUCCESS BEGINGS
    case Types.CREATE_PRODUCT_SUCCESS:
    case Types.BULK_PRODUCT_UPLOAD_SUCCESS:
    case Types.SET_PRICE_SUCCESS:
    case Types.CREATE_CUSTOM_PROPERTY_SUCCESS:
    case Types.UPDATE_CUSTOM_PROPERTY_SUCCESS:
    case Types.DELETE_CUSTOM_PROPERTY_SUCCESS:
    case Types.CREATE_UNIT_SUCCESS:
    case Types.UPDATE_UNIT_SUCCESS:
    case Types.DELETE_UNIT_SUCCESS:
    case Types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
      };
    case Types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        products: action.products,
      };
    case Types.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        productSearchResult: action.productSearchResult ?? [],
      };
    case Types.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        product: action.product,
      };
    case Types.FETCH_CUSTOM_PROPERTIES_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        cp: action.cp,
      };
    case Types.FETCH_CUSTOM_PROPERTY_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        cpDetails: action.cpDetails,
      };
    case Types.FETCH_PRODUCT_POS_SUCCESS:
      return {
        ...state,
        isProductPosLoading: false,
        productPos: action.productPos ?? [],
      };
    case Types.FETCH_RESTOCK_LOCATIONS_SUCCESS:
      return {
        ...state,
        isRestockLocationLoading: false,
        restockLocations: action.restockLocations ?? [],
      };
    case Types.FETCH_UNITS_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        uoms: action.uoms,
      };
    case Types.FETCH_PRODUCT_UNITS_SUCCESS:
      return {
        ...state,
        isProductUnitLoading: false,
        uom: action.uom,
      };
    // SUCCESS ENDS
    // END BEGINGS
    case Types.CREATE_PRODUCT_END:
    case Types.BULK_PRODUCT_UPLOAD_END:
    case Types.FETCH_PRODUCTS_END:
    case Types.SEARCH_PRODUCTS_END:
    case Types.FETCH_PRODUCT_END:
    case Types.SET_PRICE_END:
    case Types.CREATE_CUSTOM_PROPERTY_END:
    case Types.FETCH_CUSTOM_PROPERTIES_END:
    case Types.FETCH_CUSTOM_PROPERTY_END:
    case Types.UPDATE_CUSTOM_PROPERTY_END:
    case Types.DELETE_CUSTOM_PROPERTY_END:
    case Types.CREATE_UNIT_END:
    case Types.FETCH_UNITS_END:
    case Types.UPDATE_UNIT_END:
    case Types.DELETE_UNIT_END:
    case Types.UPDATE_PRODUCT_END:
      return {
        ...state,
        isProductActionLoading: false,
        message: action.message,
      };
    case Types.FETCH_PRODUCT_POS_END:
      return {
        ...state,
        isProductPosLoading: false,
        message: action.message,
      };
    case Types.FETCH_RESTOCK_LOCATIONS_END:
      return {
        ...state,
        isRestockLocationLoading: false,
        message: action.message,
      };
    case Types.FETCH_PRODUCT_UNITS_END:
      return {
        ...state,
        isProductUnitLoading: false,
        message: action.message,
      };
    // END ENDS
    default:
      return state;
  }
};
