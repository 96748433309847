import React, { FC } from "react";
import "../../styles.scss";
import { IProductDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const ProductDetails: FC<IProductDetails> = ({ product, goBack, complete }) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  return (
    <div className="w-full">
      <div className="flex flex-col items-center drawer-content-height">
        {product?.image && (
          <p className="font-semibold text-x-sm-headline mb-6">
            Is this what you wanted?
          </p>
        )}
        <div className="mb-10">
          {product?.image && (
            <img
              src={URL.createObjectURL(product?.image as any)}
              alt={"imagefile"}
              className={"h-[150px]"}
            />
          )}
        </div>
        <ProductInfo label={"Product name"} value={product?.name ?? ""} />
        <ProductInfo label={"UPC"} value={product?.upc ?? ""} />
        <ProductInfo label={"Brand"} value={product?.brand ?? ""} />
        <ProductInfo label={"Category"} value={product?.category ?? ""} />
        <ProductInfo label={"Weight"} value={product?.weight ?? ""} />
        <ProductInfo label={"Color"} value={product?.color ?? ""} />
        <ProductInfo
          label={"Retail price"}
          value={`N${product?.retail_price}`}
        />
        <ProductInfo label={"Description"} value={product?.description ?? ""} />
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-4"}
              state={ButtonState.PRIMARY}
              text={"Save product"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductInfo: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 text-left text-gray-300">{label}</div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

export { ProductDetails };
