import React, { FC, ReactElement } from "react";
import "../../styles.scss";
import { ISellingDetailsPreview } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockSupplier from "../../../../assets/imgs/quicstock-supplier.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { formatCurrency, NAIRA } from "../../../../utils/constants";

const SellingDetailsPreview: FC<ISellingDetailsPreview> = ({
  retailPrice,
  goBack,
  complete,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );

  const validCustomProperties =
    retailPrice.pricing_rules.custom_properties.filter((cp) =>
      cp.variants.some((cpv) => cpv.price !== null && cpv.price !== "")
    );

  return (
    <div className="w-full">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="mb-6">
            <img
              src={QuicstockSupplier}
              alt={"Preview"}
              className={"h-[150px]"}
            />
          </div>
          <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4 mb-4 w-full">
            <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
              General
            </p>
            <PricingInfo
              label="Average Cost Price"
              value={`${NAIRA}${formatCurrency(retailPrice.averageCostPrice.split(",").join())}`}
            />
            <PricingInfo
              label="Current Cost Price"
              value={`${NAIRA}${formatCurrency(retailPrice.costPrice.split(",").join())}`}
            />
            <PricingInfo
              label="Include VAT"
              value=""
              component={
                retailPrice?.includeVat ? (
                  <p className="text-link-green px-2 py-1 bg-instock-bg text-labels font-semibold rounded-[24px]">
                    Yes
                  </p>
                ) : (
                  <p className="text-declined-main px-2 py-1 bg-declined-bg text-labels font-semibold rounded-[24px]">
                    No
                  </p>
                )
              }
            />
            {retailPrice?.percentage || retailPrice?.amount ? (
              <PricingInfo
                label={`Markup options (${retailPrice?.percentage ? "percentage" : "amount"})`}
                value={`${retailPrice?.percentage ? retailPrice?.percentage + "%" : formatCurrency(retailPrice?.amount?.split(",").join())}`}
              />
            ) : (
              <></>
            )}
            <PricingInfo
              label="Updated Cost Price"
              value={`${NAIRA}${formatCurrency(retailPrice.sellingPrice.split(",").join())}`}
            />
            {retailPrice?.discountPercentage || retailPrice?.discountAmount ? (
              <PricingInfo
                label={`Discount options (${retailPrice?.discountPercentage ? "percentage" : "amount"})`}
                value={`${retailPrice?.discountPercentage ? retailPrice?.discountPercentage + "%" : formatCurrency(retailPrice?.discountAmount?.split(",").join())}`}
              />
            ) : (
              <></>
            )}
            <PricingInfo
              label="Discount"
              value={`${NAIRA}${formatCurrency((retailPrice.pricing_rules.discount || 0).toString().split(",").join())}`}
            />
          </div>
          {validCustomProperties.length > 0 && (
            <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4 mb-4 w-full">
              <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                Pricing rules (custom properties)
              </p>
              <div className="flex flex-col gap-3">
                {validCustomProperties.map((cp, index) => {
                  const validVariants = cp.variants.filter(
                    (cpv) => cpv.price !== null && cpv.price !== ""
                  );

                  return (
                    <div key={cp.custom_property_id + index}>
                      <p className="text-sm-regular font-medium mb-4">
                        {cp.custom_property_name}
                      </p>
                      {validVariants.map((cpv, jpod) => (
                        <PricingInfo
                          className="text-labels"
                          key={cpv.variant_id + jpod}
                          label={cpv.variant_display_name}
                          value={`${NAIRA}${formatCurrency(cpv.price?.toString()?.split(",").join(""))}`}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {retailPrice.pricing_rules.bulk_pricing.filter(
            (bp) => bp.min_quantity && bp.price
          ).length > 0 ? (
            <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4 mb-4 w-full">
              <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                Bulk pricing
              </p>
              <div className="flex flex-col gap-3">
                {retailPrice.pricing_rules.bulk_pricing
                  .filter((bp) => bp.min_quantity && bp.price)
                  .map((bp, index, filteredList) => (
                    <div key={bp.price + bp.min_quantity + index}>
                      <PricingInfo
                        className="text-labels"
                        label="Minimum quantity"
                        value={`${formatCurrency((bp.min_quantity ?? 0).toString().split(",").join(""))}`}
                      />
                      <PricingInfo
                        label="Price"
                        value={`${NAIRA}${formatCurrency((bp.price ?? 0).toString().split(",").join(""))}`}
                        className="!mb-0 text-labels"
                      />
                      {index < filteredList.length - 1 && (
                        <div className="border-t-[1px] border-[#F6F6F6] my-2"></div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-0"}
              state={ButtonState.PRIMARY}
              text={"Save changes"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingInfo: FC<{
  label: string;
  value: string;
  component?: ReactElement;
  className?: string;
}> = ({ label, value, component, className }) => {
  return (
    <div
      className={`flex justify-between w-full mb-4 ${className ? className : ""}`}
    >
      <div className="w-1/2 text-left text-[#667185]">{label}</div>
      {component ? (
        component
      ) : (
        <div className="w-1/2 text-right text-[#344054]">{value || "-"}</div>
      )}
    </div>
  );
};

export { SellingDetailsPreview };
