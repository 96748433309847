/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ICustomPropertyPO, IAddNewCustomPropertyForm } from "../../model";
import { Field, FieldArray, Formik } from "formik";
import { DatePicker, Form, Input, Select, Spin } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import { VALUE_TYPES } from "../../../../utils/constants";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import { ReactComponent as QuicstockDot } from "../../../../assets/svgs/quicstock-dot.svg";
import { ReactComponent as QuicstockConnector } from "../../../../assets/svgs/quicstock-connector.svg";
import { ReactComponent as QuicstockMinusDeep } from "../../../../assets/svgs/quicstock-minu-deep.svg";
import { ReactComponent as QuicstockAdd } from "../../../../assets/svgs/quicstock-add.svg";
import {
  CustomPropertyBaseModel,
  CustomPropertyPO,
} from "../../../../model/product.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { FetchProductPOs } from "../../../../redux/actions/products.action";
import openNotification from "../../../../components/Notification/notification";
import NotificationMessage from "../../../../components/Notification";

const AddNewCustomPropertyForm: FC<IAddNewCustomPropertyForm> = ({
  submit,
  cancel,
  productId,
  singleCp,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const isProductPosLoading = useSelector(
    (state: RootState) => state.products.isProductPosLoading
  );
  const productPos = useSelector(
    (state: RootState) => state.products.productPos
  );
  const [ppo, setPpo] = useState<ICustomPropertyPO[]>([]);
  const [values] = useState<CustomPropertyBaseModel>({
    product_id: "",
    custom_properties: [
      {
        property_name: "",
        property_type: "",
        property_value: [],
      },
    ],
  });

  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    const hasError = values.custom_properties.some((property: any) =>
      property.property_value.some((item: any) => item.error?.trim())
    );

    if (hasError) return;
    submit(values);
  };

  useEffect(() => {
    dispatch(FetchProductPOs(productId)).catch((err) => {
      openNotification(
        <NotificationMessage
          type="error"
          title="Error"
          message={err?.message}
        />,
        true
      );
      cancel();
    });
  }, [singleCp]);

  useEffect(() => {
    const ppo: ICustomPropertyPO[] = productPos.map((p, index) => {
      return {
        id: index + 1,
        po_number: p?.po_number ?? "0",
        quantity: p?.quantity ?? 0,
        is_checked: false,
      };
    });
    setPpo(ppo);
  }, [productPos, singleCp]);

  return (
    <div className="w-full h-full">
      <Formik
        className="w-full"
        initialValues={values}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
        validationSchema={validator}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          const addItem = (jpod: number) => {
            const newItem = {
              value: "",
              is_global: true,
              po_numbers: [],
              error: "",
              cPos: [...(ppo ?? [])],
            };
            const updatedItems = [
              ...values.custom_properties[jpod].property_value,
              newItem,
            ];
            setFieldValue(
              `custom_properties[${jpod}].property_value`,
              updatedItems
            );
          };

          const removeItem = (jpod: number, index: number) => {
            const updatedItems = values.custom_properties[
              jpod
            ].property_value.filter((_, i) => i !== index);
            setFieldValue(
              `custom_properties[${jpod}].property_value`,
              updatedItems
            );
          };

          const onChange = (
            is_checked: boolean,
            jpod: number,
            index: number
          ) => {
            const updatedItems = values.custom_properties[
              jpod
            ].property_value.map((item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  is_global: is_checked,
                  po_numbers: is_checked ? [] : item.po_numbers,
                  error: !is_checked
                    ? "Property should either be global or link to at least one PO"
                    : "",
                  cPos: is_checked
                    ? item.cPos.map((po) => ({ ...po, is_checked: false }))
                    : item.cPos,
                };
              }
              return item;
            });

            setFieldValue(
              `custom_properties[${jpod}].property_value`,
              updatedItems
            );
          };

          const handleSelectedPo = (
            is_checked: boolean,
            poId: number,
            jpod: number,
            index: number
          ) => {
            const updatedItems = values.custom_properties[
              jpod
            ].property_value.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    error: "",
                    cPos: item.cPos.map((po) =>
                      po.id === poId ? { ...po, is_checked } : po
                    ),
                    po_numbers: is_checked
                      ? Array.from(
                          new Set([
                            ...item.po_numbers,
                            item.cPos.find((po) => po.id === poId)?.po_number!,
                          ])
                        )
                      : item.po_numbers.filter(
                          (poNumber) =>
                            poNumber !==
                            item.cPos.find((po) => po.id === poId)?.po_number
                        ),
                  }
                : { ...item, error: "" }
            );

            const allUnchecked = updatedItems[index].cPos.every(
              (po) => !po.is_checked
            );
            updatedItems[index].is_global = allUnchecked;

            setFieldValue(
              `custom_properties[${jpod}].property_value`,
              updatedItems
            );
          };

          return (
            <Form
              onFinish={handleSubmit}
              form={form}
              className="h-full flex flex-col"
              style={{ height: "calc(100vh - 165px)" }}
            >
              <div className="flex flex-col flex-grow overflow-auto">
                <FieldArray name="custom_properties">
                  {({ push, remove }) => (
                    <>
                      {values.custom_properties.map((item, jpod) => (
                        <React.Fragment key={jpod + "jpod"}>
                          <div className={`w-full`}>
                            <h2 className="text-sm-regular mb-2">
                              New property
                            </h2>
                            <div className="grid grid-cols-12 gap-x-3 mb-2">
                              <div className={"col-span-7"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                  <Form.Item
                                    name={`custom_properties[${jpod}].property_name`}
                                    validateStatus={
                                      touched.custom_properties?.[jpod]
                                        ?.property_name &&
                                      (errors as any).custom_properties?.[jpod]
                                        ?.property_name
                                        ? "error"
                                        : "success"
                                    }
                                    help={
                                      touched.custom_properties?.[jpod]
                                        ?.property_name &&
                                      (errors as any).custom_properties?.[jpod]
                                        ?.property_name
                                        ? (errors as any).custom_properties?.[
                                            jpod
                                          ]?.property_name
                                        : ""
                                    }
                                  >
                                    <Field>
                                      {({ form: { setFieldValue } }: any) => (
                                        <Input
                                          type={"text"}
                                          className={"form-field_input_2"}
                                          value={
                                            values?.custom_properties[jpod]
                                              .property_name || ""
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `custom_properties[${jpod}].property_name`,
                                              e.target.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            setFieldValue(
                                              `custom_properties[${jpod}].property_name`,
                                              e.target.value
                                            );
                                          }}
                                          placeholder="Property name"
                                        />
                                      )}
                                    </Field>
                                  </Form.Item>
                                </FieldContainer>
                              </div>
                              <div
                                className={
                                  values?.custom_properties[jpod].property_type
                                    ? "col-span-3"
                                    : "col-span-5"
                                }
                              >
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                  <Form.Item
                                    name={`custom_properties[${jpod}].property_type`}
                                    validateStatus={
                                      touched.custom_properties?.[jpod]
                                        ?.property_type &&
                                      (errors as any).custom_properties?.[jpod]
                                        ?.property_type
                                        ? "error"
                                        : "success"
                                    }
                                    help={
                                      touched.custom_properties?.[jpod]
                                        ?.property_type &&
                                      (errors as any).custom_properties?.[jpod]
                                        ?.property_type
                                        ? (errors as any).custom_properties?.[
                                            jpod
                                          ]?.property_type
                                        : ""
                                    }
                                  >
                                    <Field>
                                      {({ form: { setFieldValue } }: any) => (
                                        <Select
                                          placeholder={"Value type"}
                                          value={
                                            values?.custom_properties[jpod]
                                              .property_type || null
                                          }
                                          onBlur={handleBlur}
                                          className={"form-field_input_3"}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `custom_properties[${jpod}].property_value`,
                                              []
                                            );
                                            setFieldValue(
                                              `custom_properties[${jpod}].property_type`,
                                              e
                                            );
                                            setTimeout(() => {
                                              const newItem = [
                                                {
                                                  value: "",
                                                  is_global: true,
                                                  po_numbers: [],
                                                  error: "",
                                                  cPos: [...(ppo ?? [])],
                                                },
                                              ];
                                              setFieldValue(
                                                `custom_properties[${jpod}].property_value`,
                                                newItem
                                              );
                                            }, 100);
                                          }}
                                          options={VALUE_TYPES}
                                        />
                                      )}
                                    </Field>
                                  </Form.Item>
                                </FieldContainer>
                              </div>
                              {values?.custom_properties[jpod]
                                .property_type && (
                                <div className={"col-span-2 flex justify-end"}>
                                  <button
                                    type="button"
                                    className="border-[1px] border-black rounded-full flex justify-center mt-1 items-center h-10 w-10"
                                    onClick={() => addItem(jpod)}
                                  >
                                    <QuicstockAdd />
                                  </button>
                                </div>
                              )}
                            </div>

                            <FieldArray name="property_value">
                              {({ push, remove }) => (
                                <>
                                  {values.custom_properties[
                                    jpod
                                  ].property_value.map((item, index) => (
                                    <React.Fragment
                                      key={index + "property_type"}
                                    >
                                      {values.custom_properties[jpod]
                                        .property_type && (
                                        <div
                                          className={`p-4 rounded-[12px] border-[1px] border-[#E4E7EC] ${
                                            index <
                                            values.custom_properties[jpod]
                                              .property_value.length -
                                              1
                                              ? "mb-4"
                                              : "mb-3"
                                          }`}
                                        >
                                          <div
                                            className={
                                              "grid grid-cols-12 gap-x-3"
                                            }
                                          >
                                            <div
                                              className={
                                                "col-span-1 flex justify-start"
                                              }
                                            >
                                              <div
                                                className="h-10 w-10"
                                                onClick={() =>
                                                  removeItem(jpod, index)
                                                }
                                              >
                                                <QuicstockConnector />
                                              </div>
                                            </div>
                                            <div className="col-span-11">
                                              <div className="w-full">
                                                {values.custom_properties[jpod]
                                                  .property_type ===
                                                  "string" && (
                                                  <FieldContainer
                                                    iconPlacementCss={"iconCss"}
                                                  >
                                                    <Form.Item
                                                      name={`custom_properties[${jpod}].property_value[${index}].value`}
                                                      validateStatus={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? "error"
                                                          : "success"
                                                      }
                                                      help={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? (errors as any)
                                                              ?.custom_properties?.[
                                                              jpod
                                                            ]?.property_value?.[
                                                              index
                                                            ]?.value
                                                          : ""
                                                      }
                                                    >
                                                      <Field>
                                                        {({
                                                          form: {
                                                            setFieldValue,
                                                          },
                                                        }: any) => (
                                                          <Input
                                                            type={"text"}
                                                            className={
                                                              "form-field_input_2"
                                                            }
                                                            value={
                                                              values
                                                                ?.custom_properties[
                                                                jpod
                                                              ].property_value[
                                                                index
                                                              ].value || ""
                                                            }
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            onBlur={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            placeholder="Property variant e.g Blue"
                                                          />
                                                        )}
                                                      </Field>
                                                    </Form.Item>
                                                  </FieldContainer>
                                                )}
                                                {values.custom_properties[jpod]
                                                  .property_type ===
                                                  "number" && (
                                                  <FieldContainer
                                                    iconPlacementCss={"iconCss"}
                                                  >
                                                    <Form.Item
                                                      name={`custom_properties[${jpod}].property_value[${index}].value`}
                                                      validateStatus={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? "error"
                                                          : "success"
                                                      }
                                                      help={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? (errors as any)
                                                              ?.custom_properties?.[
                                                              jpod
                                                            ]?.property_value?.[
                                                              index
                                                            ]?.value
                                                          : ""
                                                      }
                                                    >
                                                      <Field>
                                                        {({
                                                          form: {
                                                            setFieldValue,
                                                          },
                                                        }: any) => (
                                                          <Input
                                                            type={"number"}
                                                            className={
                                                              "form-field_input_2"
                                                            }
                                                            value={
                                                              values
                                                                ?.custom_properties[
                                                                jpod
                                                              ].property_value[
                                                                index
                                                              ].value || ""
                                                            }
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            onBlur={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            placeholder="Property value"
                                                          />
                                                        )}
                                                      </Field>
                                                    </Form.Item>
                                                  </FieldContainer>
                                                )}
                                                {values.custom_properties[jpod]
                                                  .property_type === "date" && (
                                                  <FieldContainer
                                                    iconPlacementCss={"iconCss"}
                                                  >
                                                    <Form.Item
                                                      name={`custom_properties[${jpod}].property_value[${index}].value`}
                                                      validateStatus={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? "error"
                                                          : "success"
                                                      }
                                                      help={
                                                        touched
                                                          .custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value &&
                                                        (errors as any)
                                                          ?.custom_properties?.[
                                                          jpod
                                                        ]?.property_value?.[
                                                          index
                                                        ]?.value
                                                          ? (errors as any)
                                                              ?.custom_properties?.[
                                                              jpod
                                                            ]?.property_value?.[
                                                              index
                                                            ]?.value
                                                          : ""
                                                      }
                                                    >
                                                      <Field>
                                                        {({
                                                          form: {
                                                            setFieldValue,
                                                          },
                                                        }: any) => (
                                                          <DatePicker
                                                            className={
                                                              "form-field_input_2"
                                                            }
                                                            placeholder={
                                                              "Property value"
                                                            }
                                                            value={
                                                              values
                                                                ?.custom_properties[
                                                                jpod
                                                              ].property_value[
                                                                index
                                                              ].value || ""
                                                            }
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e
                                                              );
                                                            }}
                                                            onOk={(e) => {
                                                              setFieldValue(
                                                                `custom_properties[${jpod}].property_value[${index}].value`,
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        )}
                                                      </Field>
                                                    </Form.Item>
                                                  </FieldContainer>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="grid grid-cols-12">
                                            <div className="col-span-1"></div>
                                            <div className="col-span-11">
                                              <div className="p-3 bg-dark-700 rounded-[8px]">
                                                <CustomCheckbox
                                                  onChange={(checked) =>
                                                    onChange(
                                                      checked,
                                                      jpod,
                                                      index
                                                    )
                                                  }
                                                  useParentValue={true}
                                                  checked={
                                                    values.custom_properties[
                                                      jpod
                                                    ]?.property_value[index]
                                                      .is_global
                                                  }
                                                >
                                                  <p className="font-medium text-labels">
                                                    Make this property a global
                                                    property
                                                  </p>
                                                </CustomCheckbox>
                                              </div>
                                              {isProductPosLoading ? (
                                                <div className="h-[50px] w-full justify-center items-center flex">
                                                  <Spin size="small" />
                                                </div>
                                              ) : values.custom_properties[jpod]
                                                  ?.property_value[index].cPos
                                                  .length > 0 ? (
                                                <div className="my-4">
                                                  <div className="xl:flex grid flex-wrap gap-3">
                                                    {values.custom_properties[
                                                      jpod
                                                    ]?.property_value[
                                                      index
                                                    ].cPos.map((po) => (
                                                      <PropertyPO
                                                        key={po.id}
                                                        checked={po.is_checked}
                                                        onChange={(
                                                          is_checked: boolean,
                                                          poId: number
                                                        ) =>
                                                          handleSelectedPo(
                                                            is_checked,
                                                            poId,
                                                            jpod,
                                                            index
                                                          )
                                                        }
                                                        po={po}
                                                      />
                                                    ))}
                                                  </div>
                                                  <p className="mt-1 text-labels text-error-form">
                                                    {
                                                      values.custom_properties[
                                                        jpod
                                                      ]?.property_value[index]
                                                        .error
                                                    }
                                                  </p>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                          {(values.custom_properties.length ===
                                            1 ||
                                            values.custom_properties[jpod]
                                              ?.property_value.length > 1) && (
                                            <div className="w-full flex justify-end">
                                              <button
                                                type="button"
                                                className="rounded-[10px] flex justify-center mt-1 items-center px-[12px] py-[5px] gap-x-2 bg-[#F0F2F5]"
                                                onClick={() =>
                                                  removeItem(jpod, index)
                                                }
                                              >
                                                <QuicstockMinusDeep />
                                                <span className="text-error-dark text-labels font-medium">
                                                  Remove
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </FieldArray>
              </div>
              <div className="w-full mt-auto left-0 right-0 bottom-0 bg-white">
                <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
                <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
                  <div className={"col-1 sm:block hidden"}></div>
                  <div className={"col-1"}>
                    <Button
                      css={
                        "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                      }
                      state={ButtonState.PRIMARY}
                      text={"Go back"}
                      type={"button"}
                      onClick={cancel}
                    />
                  </div>
                  <div className={"col-1"}>
                    <Button
                      css={"w-full !px-0 !rounded-[6px]"}
                      state={ButtonState.PRIMARY}
                      text={"Save changes"}
                      type={"submit"}
                      isLoading={isLoading || isProductPosLoading}
                      disabled={
                        values.custom_properties[0].property_value.length < 1
                      }
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const PropertyPO: FC<{
  onChange: (checked: boolean, poId: number) => void;
  checked: boolean;
  po: CustomPropertyPO;
}> = ({ onChange, checked, po }) => {
  return (
    <div className="p-3 bg-dark-200 rounded-[8px] text-labels">
      <CustomCheckbox
        checked={checked}
        onChange={(is_checked) => onChange(is_checked, po.id)}
        placeBefore={true}
        useParentValue={true}
      >
        <div className="flex items-center gap-x-2">
          <p className="font-medium">{po.po_number}</p>
          <QuicstockDot className="product-separator-secondary" />
          <p className="text-labels text-link-green font-medium">
            {po.quantity}
          </p>
        </div>
      </CustomCheckbox>
    </div>
  );
};

const validator = yup.object().shape({
  custom_properties: yup.array().of(
    yup.object({
      property_name: yup
        .string()
        .min(3, "Property name should be minimum of 6 characters")
        .required("Property name is required"),
      property_type: yup.string().required("Property value type is required"),
      property_value: yup.array().of(
        yup.object({
          value: yup.string().required("Property value is required"),
        })
      ),
    })
  ),
});

export { AddNewCustomPropertyForm };
