import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { AddProduct, AllProducts } from "./components";
import { useNavigate } from "react-router-dom";
import { ImportProducts } from "./components/ImportProducts";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import {
  ProductItemModel,
  ProductResponseModel,
} from "../../../model/product.model";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  BulkProductUpload,
  CreateProduct,
  FetchProducts,
} from "../../../redux/actions/products.action";
import { increment } from "../../../utils/constants";
import { ApiPaginationModel } from "../../../model/base.model";

const Products = () => {
  const navigate = useNavigate();
  const [openProductDrawer, setOpenProductDrawer] = useState(false);
  const [openImportProducts, setOpenImportProducts] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [importedProducts, setImportedProducts] = useState<ProductItemModel[]>(
    []
  );
  const [importedProductsFile, setImportedProductsFile] = useState<any>(null);
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    useState<number>(0);
  const [cancelUploadProgress, setCancelUploadProgress] = useState<any>(null);
  const [product, setProduct] = useState<ProductItemModel>({});
  const [products] = useState<ProductItemModel[]>([product]);
  const dispatch: AppDispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<ProductItemModel[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const toggleProductDrawer = () => {
    setOpenProductDrawer(!openProductDrawer);
  };

  const toggleImportProducts = () => {
    setOpenImportProducts(!openImportProducts);
  };

  useEffect(() => {
    console.log(search);
  }, [search]);

  const handleViewProduct = (product: ProductItemModel) => {
    setProduct(product);
    navigate(`/dashboard/product/products/${product.id}`);
  };

  const handleSubmit = (data: any[], file: any) => {
    setImportedProducts(data);
    setImportedProductsFile(file);
    setActiveStep(1);
    const cancel = increment(setUploadProgressPercentage);
    setCancelUploadProgress(() => cancel);
    return () => {
      cancel();
    };
  };

  const handleProductSubmit = (values: ProductItemModel) => {
    setProduct(values);
    setActiveStep(1);
  };

  const handleProductComplete = () => {
    createProduct();
  };

  const handleComplete = () => {
    bulkProductUpload();
  };

  const handleCancelUpload = () => {
    setUploadProgressPercentage(0);
    setActiveStep(0);
    if (cancelUploadProgress) {
      cancelUploadProgress();
    }
  };

  const handleCancel = () => {
    handleCancelUpload();
    toggleImportProducts();
    setActiveStep(0);
  };

  const createProduct = () => {
    const formData = new FormData();
    formData.append("name", product?.name ?? "");
    formData.append("upc", product?.upc ?? "");
    formData.append("brand", product?.brand ?? "");
    formData.append("category", product?.category ?? "");
    formData.append("weight", product?.weight ?? "");
    formData.append("color", product?.color ?? "");
    formData.append("description", product?.description ?? "");
    formData.append("retail_price", product?.retail_price?.split(",").join("") ?? "");
    formData.append("image", product?.image ?? "");
    dispatch(CreateProduct(formData))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New product added"
            message={res?.message}
          />,
          true
        );
        toggleProductDrawer();
        setActiveStep(0);
        dispatch(FetchProducts(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const bulkProductUpload = () => {
    const formData = new FormData();
    formData.append("products", importedProductsFile);
    dispatch(BulkProductUpload(formData))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Upload successful"
            message={"Product uploaded successfuly"}
          />,
          true
        );
        toggleImportProducts();
        setActiveStep(0);
        setUploadProgressPercentage(0);
        if (cancelUploadProgress) {
          cancelUploadProgress();
        }
        dispatch(FetchProducts(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchProducts(paginationData));
  }, [dispatch, paginationData]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Products"}
        subtitle={"Products in"}
        placeholder={"Search"}
        buttonText={"Add products"}
        getSearchValue={setSearch}
        handleClick={toggleProductDrawer}
      />
      <div className="default-page-height w-full">
        {products.length ? (
          <AllProducts
            viewProduct={handleViewProduct}
            handleImport={() => {
              setActiveStep(0);
              handleCancelUpload();
              toggleImportProducts();
            }}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : (
          <EmptyContent
            label={"You have no products information yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonLabel={"Add new products"}
            handlePrimaryButtonClick={toggleProductDrawer}
            secondaryButtonLabel={"Import products"}
            handleSecondaryButtonClick={() => {
              setActiveStep(0);
              handleCancelUpload();
              toggleImportProducts();
            }}
          />
        )}
      </div>

      {/*  Product */}
      <AppDrawer
        open={openProductDrawer}
        toggle={toggleProductDrawer}
        title={"Add product"}
        subtitle={"Add a new product"}
      >
        <AddProduct
          submit={handleProductSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleProductComplete}
          cancel={toggleProductDrawer}
          activeStep={activeStep}
          product={product}
        />
      </AppDrawer>
      {/*  Product */}

      {/* Import products */}
      <AppDrawer
        open={openImportProducts}
        toggle={() => {
          setActiveStep(0);
          handleCancelUpload();
          toggleImportProducts();
        }}
        title={"Import your data"}
        subtitle={"Do you want to import?"}
      >
        <ImportProducts
          submit={handleSubmit}
          complete={handleComplete}
          cancel={handleCancel}
          cancelUpload={handleCancelUpload}
          activeStep={activeStep}
          importedProducts={importedProducts}
          uploadProgressPercentage={uploadProgressPercentage}
        />
      </AppDrawer>
      {/* Import products */}
    </div>
  );
};

export default Products;
