import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import ReactECharts from "echarts-for-react";
import { Select } from "antd";

const option = {
  grid: { top: 24, right: 8, bottom: 24 },
  tooltip: {
    trigger: "axis",
    formatter: (params: any) => {
      const data = params[0];
      return `${data.axisValue}<br/>N${data.value.toLocaleString()}`;
    },
    backgroundColor: "#252547",
    borderColor: "#fff",
    textStyle: {
      color: "#fff",
    },
  },
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
    axisLine: {
      lineStyle: { color: "#aaa" },
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "N{value}",
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: "#ddd",
      },
    },
  },
  series: [
    {
      data: [300, 1000, 5500, 7000, 12000, 25000, 50000],
      type: "line",
      smooth: true,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: "rgba(30, 80, 200, 0.4)" },
            { offset: 1, color: "rgba(30, 80, 200, 0)" },
          ],
        },
      },
      lineStyle: {
        color: "#2050ff",
        width: 2,
      },
      symbol: "circle",
      symbolSize: 8,
      itemStyle: {
        color: "#2050ff",
        borderColor: "#fff",
        borderWidth: 2,
      },
    },
  ],
};

const SalesReport: FC = () => {
  const [chartOption, setChartOption] = useState<any>({});
  const [valueType, setValueType] = useState<string>("");

  useEffect(() => {
    setChartOption({ ...option });
  }, []);

  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-headline font-medium">Sales report</p>
        <Select
          placeholder={"Value type"}
          value={valueType || null}
          className={"overview-field !w-fit"}
          onChange={(e: any) => {
            setValueType(e);
          }}
          options={[
            { value: "A", label: "Type A" },
            { value: "B", label: "Type B" },
          ]}
        />
      </div>
      <ReactECharts option={chartOption} style={{ height: 400 }} />
    </div>
  );
};

export { SalesReport };
