import React, { FC } from "react";
import "../styles.scss";
import { IAuthHeading } from "../model";

const AuthHeading: FC<IAuthHeading> = ({ children, subHeading }) => {
  return (
    <>
      <div className="w-full text-left">
        <p
          className={`text-[20px] font-semibold text-black text-center ${children ? "mb-2" : ""}`}
        >
          {subHeading}
        </p>
        {children}
      </div>
    </>
  );
};

export { AuthHeading };
