import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockNote from "../../../assets/imgs/quicstock-note.png";
import { useNavigate } from "react-router-dom";
import {
  AddNewCustomPropertyForm,
  AllCustomProperties,
  EditCustomPropertyForm,
  ViewCustomProperty,
} from "./components";
import AppDrawer from "../../../components/AppDrawer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  CreateCustomProperty,
  DeleteCustomProperty,
  FetchCustomProperties,
  FetchCustomProperty,
  UpdateCustomProperty,
} from "../../../redux/actions/products.action";
import { RootState } from "../../../redux/reducers";
import {
  CPModel,
  CustomPropertyBaseModel,
  CustomPropertyDetailsModel,
  CustomPropertyListModel,
  ProductResponseModel,
} from "../../../model/product.model";
import Loader from "../../../components/Loader";
import Confirm from "../../../components/Confirm";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import moment from "moment";

const CustomProperties = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const cp = useSelector((state: RootState) => state.products.cp);
  const [cpDetails, setCpDetails] = useState<CustomPropertyDetailsModel>({
    product_name: "",
    product_id: "",
    image_url: "",
    custom_properties: [],
  });
  const [singleCp, setSingleCp] = useState<CustomPropertyListModel>({
    custom_property_id: "",
    custom_property_name: "",
    custom_property_type: "",
    custom_property_values: [],
  });
  const [openViewCPDrawer, setOpenViewCPDrawer] = useState(false);
  const [openEditCPDrawer, setOpenEditCPDrawer] = useState(false);
  const [openAddNewCPDrawer, setOpenAddNewCPDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [customProperty, setCustomProperty] = useState<CPModel>({
    id: "",
    name: "",
    custom_property_count: 0,
    image_url: "",
  });
  const [level, setLevel] = useState<number>(0);

  const addNewCustomProperty = () => {
    navigate("/dashboard/product/custom-properties/new");
  };

  const toggleViewCPDrawer = () => {
    setOpenViewCPDrawer(!openViewCPDrawer);
  };

  const toggleEditCPDrawer = () => {
    setOpenEditCPDrawer(!openEditCPDrawer);
  };

  const toggleAddNewCPDrawer = () => {
    setOpenAddNewCPDrawer(!openAddNewCPDrawer);
  };

  const handleCloseEdit = () => {
    toggleEditCPDrawer();
    toggleViewCPDrawer();
  };

  const handleView = (cp: CPModel) => {
    setCustomProperty(cp);
    getSingleCustomProperty(true, cp);
  };

  const getSingleCustomProperty = (toggle: boolean, cp?: CPModel) => {
    dispatch(FetchCustomProperty(cp?.id ?? customProperty.id))
      .then((res) => {
        setCpDetails(res?.data);
        if (toggle) toggleViewCPDrawer();
      })
      .catch((err) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const handleEdit = (singleProperty: CustomPropertyListModel) => {
    setSingleCp(singleProperty);
    toggleViewCPDrawer();
    toggleEditCPDrawer();
  };

  const handleDelete = (singleProperty: CustomPropertyListModel) => {
    setSingleCp(singleProperty);
    setOpen(true);
  };

  const handleSubmit = (values: CustomPropertyBaseModel) => {
    updateCustomProperty(values);
  };

  const handleAddCp = (l: number) => {
    setLevel(l);
    if (l === 1) {
      toggleViewCPDrawer();
    }
    if (l === 2) {
      toggleEditCPDrawer();
    }
    toggleAddNewCPDrawer();
  };

  const handleCloseAddNewCp = () => {
    toggleAddNewCPDrawer();
    if (level === 1) {
      toggleViewCPDrawer();
    }
    if (level === 2) {
      toggleEditCPDrawer();
    }
  };

  const handleSubmitNewCustomProperties = (values: CustomPropertyBaseModel) => {
    const payload: CustomPropertyBaseModel = {
      product_id: cpDetails.product_id,
      custom_properties: values.custom_properties.map((property) => ({
        property_name: property.property_name,
        property_type: property.property_type,
        property_value:
          property.property_type === "date"
            ? property.property_value.map((item) => ({
                po_numbers: item.po_numbers,
                is_global: item.is_global,
                value: moment(item.value).format("YYYY-MM-DD"),
              }))
            : property.property_type === "number"
              ? property.property_value.map((item) => ({
                  po_numbers: item.po_numbers,
                  is_global: item.is_global,
                  value: parseInt(item.value),
                }))
              : property.property_value,
      })) as any,
    };
    createCustomProperty(payload);
  };

  const updateCustomProperty = (values: CustomPropertyBaseModel) => {
    dispatch(UpdateCustomProperty(singleCp.custom_property_id, values))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Custom property updated"
            message={res?.message}
          />,
          true
        );
        toggleEditCPDrawer();
        dispatch(FetchCustomProperties());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteCustomProperty = () => {
    dispatch(DeleteCustomProperty(singleCp.custom_property_id))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Custom property deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchCustomProperties());
        getSingleCustomProperty(false);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const createCustomProperty = (values: CustomPropertyBaseModel) => {
    dispatch(CreateCustomProperty(values))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New custom property added"
            message={res?.message}
          />,
          true
        );
        handleCloseAddNewCp();
        getSingleCustomProperty(false, customProperty);
        dispatch(FetchCustomProperties());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchCustomProperties());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={false}
        title={"Custom properties"}
        subtitle={"Fill the details below to create a new custom property"}
        buttonText={"New property"}
        handleClick={addNewCustomProperty}
      />
      <div className="default-page-height w-full">
        <Loader isLoading={isLoading} />
        {cp?.length ? (
          <AllCustomProperties view={handleView} />
        ) : (
          <EmptyContent
            label={"No custom properties here yet!"}
            note={"Why not add one or two?"}
            component={
              <img
                src={QuicstockNote}
                alt={"QuicstockNote"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Create new custom property"}
            handlePrimaryButtonClick={addNewCustomProperty}
          />
        )}
      </div>

      {/* View custom property */}
      <AppDrawer
        open={openViewCPDrawer}
        toggle={toggleViewCPDrawer}
        title={customProperty.name}
        subtitle={`View the custom properties in ${customProperty.name?.toLowerCase()}`}
      >
        <ViewCustomProperty
          editCp={handleEdit}
          deleteCp={handleDelete}
          cpDetails={cpDetails}
          add={() => handleAddCp(1)}
        />
      </AppDrawer>
      {/* View custom property */}

      {/* Edit custom property */}
      <AppDrawer
        open={openEditCPDrawer}
        toggle={handleCloseEdit}
        title={"Edit property"}
        subtitle={"View your product"}
      >
        <EditCustomPropertyForm
          submit={handleSubmit}
          cancel={handleCloseEdit}
          productId={cpDetails.product_id}
          singleCp={singleCp}
          add={() => handleAddCp(2)}
        />
      </AppDrawer>
      {/* Edit custom property */}

      {/* Add new custom property */}
      <AppDrawer
        open={openAddNewCPDrawer}
        toggle={handleCloseAddNewCp}
        title={"Add new custom property"}
      >
        <AddNewCustomPropertyForm
          submit={handleSubmitNewCustomProperties}
          cancel={handleCloseAddNewCp}
          productId={cpDetails.product_id}
          singleCp={singleCp}
        />
      </AppDrawer>
      {/* Add new custom property */}

      <Confirm
        title="Delete Custom Property"
        confirmButtonText="Delete"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteCustomProperty}
      />
    </div>
  );
};

export default CustomProperties;
