import React, { useEffect, useState } from "react";
import "../styles.scss";
import Back from "../../../components/Back";
import QuicstockNoImage from "../../../assets/imgs/quicstock-no-image.png";
import {
  AddCustomProperties,
  EditProduct,
  EditSellingDetails,
  ProductDetailsItem,
  ProductLocation,
  ProductOrder,
  ProductPurchaseOrder,
} from "./components";
import AppDrawer from "../../../components/AppDrawer";
import { ManageCustomProperties } from "./components/ManageCustomProperties";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { useParams } from "react-router-dom";
import { formatCurrency, goBack, NAIRA } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  FetchProduct,
  FetchUnits,
  SetRetailPrice,
  UpdateProduct,
} from "../../../redux/actions/products.action";
import { RootState } from "../../../redux/reducers";
import {
  EditProductModel,
  ProductResponseModel,
  SellingRetailPriceModel,
  SellingRetailPriceRequestModel,
} from "../../../model/product.model";
import { AuthHeading } from "../../Auth/components";
import { Tooltip } from "antd";
import QuicstockInfo from "../../../assets/svgs/quicstock-info.svg";

const ProductDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const product = useSelector((state: RootState) => state.products.product);
  const [openEditSellingDetails, setOpenEditSellingDetails] = useState(false);
  const [openManageCustomProperties, setOpenManageCustomProperties] =
    useState(false);
  const [openAddCustomProperties, setOpenAddCustomProperties] = useState(false);
  const [openEditProductDetailsDrawer, setOpenEditProductDetailsDrawer] =
    useState(false);
  const [id, setId] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<EditProductModel>({
    name: "",
    upc: "",
    brand: "",
    category: "",
    weight: "",
    color: "",
    description: "",
    uom: [],
    uoms: [],
  });
  const [retailPrice, setRetailPrice] = useState<SellingRetailPriceModel>({
    averageCostPrice: "",
    costPrice: "",
    sellingPrice: "",
    mode: "",
    discountMode: "",
    percentage: "",
    amount: "",
    discountPercentage: "",
    discountAmount: "",
    includeVat: false,
    pricing_rules: {
      custom_properties: [],
      bulk_pricing: [],
      discount: "",
    },
  });

  const toggleEditSellingDetails = () => {
    setOpenEditSellingDetails(!openEditSellingDetails);
  };

  const toggleManageCustomProperties = () => {
    setOpenManageCustomProperties(!openManageCustomProperties);
  };

  const toggleAddCustomProperties = () => {
    setOpenAddCustomProperties(!openAddCustomProperties);
  };

  const toggleEditProductDetailsDrawer = () => {
    setOpenEditProductDetailsDrawer(!openEditProductDetailsDrawer);
  };

  const handleSaveSellingDetails = (values: SellingRetailPriceModel) => {
    setRetailPrice(values);
    setActiveStep(1);
  };

  const handleAddNewProperty = () => {
    toggleManageCustomProperties();
    toggleAddCustomProperties();
  };

  const handleGoBackToManageCustomProperties = () => {
    toggleAddCustomProperties();
    toggleManageCustomProperties();
  };

  const handleSubmit = (values: any) => {
    setProductDetails(values);
    setActiveStep(1);
  };

  const handleComplete = () => {
    updateProduct();
  };

  const handleSubmitSellingDetails = () => {
    updateRetailPrice();
  };

  const handleSaveProperties = () => {
    openNotification(
      <NotificationMessage
        type="success"
        title="Custom property saved"
        message="You’ve successfully saved a new custom property"
      />,
      true
    );
    toggleManageCustomProperties();
  };

  const handleSaveCustomProperties = () => {
    openNotification(
      <NotificationMessage
        type="success"
        title="Custom property added"
        message="You’ve successfully added a new custom property"
      />,
      true
    );
    toggleAddCustomProperties();
    toggleManageCustomProperties();
  };

  const cleanPricingData = (data: SellingRetailPriceRequestModel) => {
    return {
      ...data,
      pricing_rules: {
        custom_properties: data.pricing_rules.custom_properties
          .map((cp) => ({
            ...cp,
            variants: cp.variants.filter(
              (v) => v.price !== "" && v.price !== null && v.price !== undefined
            ),
          }))
          .filter((cp) => cp.variants.length > 0), // Remove if no valid variants

        bulk_pricing: data.pricing_rules.bulk_pricing.filter(
          (bp) =>
            bp.min_quantity !== "" &&
            bp.min_quantity !== null &&
            bp.min_quantity !== undefined &&
            bp.price !== "" &&
            bp.price !== null &&
            bp.price !== undefined
        ),
      },
    };
  };

  const updateRetailPrice = () => {
    const payload: SellingRetailPriceRequestModel = {
      new_price: parseFloat(retailPrice?.sellingPrice.split(",").join("")),
      pricing_rules: {
        custom_properties: retailPrice.pricing_rules.custom_properties.map(
          (cp, index) => {
            return {
              ...cp,
              variants: cp.variants.map((cpv, jpod) => {
                return {
                  variant_id: cpv.variant_id,
                  variant_name: cpv.variant_name,
                  price: cpv.price
                    ? parseFloat(cpv.price.split(",").join(""))
                    : "",
                };
              }),
            };
          }
        ),
        bulk_pricing: retailPrice.pricing_rules.bulk_pricing.map(
          (bp, index) => {
            return {
              min_quantity: bp?.min_quantity
                ? parseFloat(bp?.min_quantity?.split(",").join(""))
                : "",
              price: bp?.price
                ? parseFloat(bp?.price?.split(",").join(""))
                : "",
            };
          }
        ),
      },
      discount: parseFloat(
        (retailPrice?.pricing_rules.discount || "0").split(",").join("")
      ),
    };

    dispatch(SetRetailPrice(id, cleanPricingData(payload)))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Changes made"
            message={res?.message}
          />,
          true
        );
        toggleEditSellingDetails();
        dispatch(FetchProduct(id));
        setActiveStep(0);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateProduct = () => {
    const formData = new FormData();
    formData.append("name", productDetails?.name ?? "");
    formData.append("upc", productDetails?.upc ?? "");
    formData.append("brand", productDetails?.brand ?? "");
    formData.append("category", productDetails?.category ?? "");
    formData.append("weight", productDetails?.weight ?? "");
    formData.append("color", productDetails?.color ?? "");
    formData.append("description", productDetails?.description ?? "");
    formData.append("uom", productDetails?.uom.join(",") ?? "");
    if (!productDetails?.isImageDeleted && productDetails?.imageFile) {
      formData.append("image", productDetails?.imageFile);
    } else if (productDetails.isImageDeleted) {
      formData.append("image", "");
    }
    dispatch(UpdateProduct(formData, id))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Product updated"
            message={res?.message}
          />,
          true
        );
        toggleEditProductDetailsDrawer();
        setActiveStep(0);
        dispatch(FetchProduct(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    if (params?.id) {
      setId(params?.id);
      dispatch(FetchProduct(params?.id));
    } else {
      goBack();
    }
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(FetchUnits());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-x-4">
          <Back isPrevious={true} />
          <AuthHeading subHeading={"Product details"}></AuthHeading>
        </div>
        <div className="flex gap-x-3 items-center">
          <button
            onClick={toggleEditProductDetailsDrawer}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300"
          >
            Edit product details
          </button>
          <button
            onClick={toggleEditSellingDetails}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300"
          >
            Edit selling details
          </button>
          <button
            onClick={toggleManageCustomProperties}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300 hidden"
          >
            Manage custom properties
          </button>
        </div>
      </div>
      <div className="default-page-height w-full mt-6">
        <div className="grid sm:grid-cols-12 grid-cols-1 gap-x-4">
          <div className="sm:col-span-6 col flex flex-col gap-y-4">
            <div className="w-full border-[0.5px] border-[#E4E7EC] rounded-[12px] p-6">
              <div className="flex items-center gap-x-4">
                <img
                  src={
                    product?.default_properties?.image_url
                      ? product?.default_properties?.image_url
                      : QuicstockNoImage
                  }
                  alt={
                    product?.default_properties?.image_url
                      ? product?.default_properties?.name
                      : "QuicstockProductImageTwo"
                  }
                  className={"xl:h-[60px] h-[48px] rounded-full"}
                />
                <p className="font-semibold xl:text-sm-headline text-labels">
                  {product?.default_properties?.name}
                </p>
              </div>
              <div className="w-full mt-6">
                <ProductDetailsItem
                  label={"Quantity"}
                  value={`${formatCurrency((product?.total_quantity ?? 0)?.toString())} Pieces`}
                />
                <ProductDetailsItem
                  label={"Color"}
                  value={product?.default_properties?.color ?? "-"}
                />
                <ProductDetailsItem
                  label={"Brand"}
                  value={product?.default_properties?.brand ?? "-"}
                />
                <ProductDetailsItem
                  label={"Cost price"}
                  value={`${NAIRA}${formatCurrency(product?.pricing?.retail_price?.toString())}`}
                />
                <ProductDetailsItem
                  label={"Category"}
                  value={product?.default_properties?.category ?? "-"}
                />
                <ProductDetailsItem
                  label={"Weight"}
                  value={`${product?.default_properties?.weight ?? "-"}g`}
                />
                <ProductDetailsItem
                  label={"Description"}
                  value={product?.default_properties?.description ?? "-"}
                />
                <ProductDetailsItem
                  label={"Unit of measurement"}
                  value={
                    product?.uom_details?.map((u) => u.name)?.join(", ") ?? "-"
                  }
                />
              </div>
            </div>
            {(product?.pricing?.pricing_rules?.custom_properties?.length ?? 0) >
            0 ? (
              <div className="w-full border-[0.5px] border-[#E4E7EC] rounded-[12px] p-6">
                <div className="w-full">
                  <div className="flex items-center mb-4 gap-x-1">
                    <p className="font-semibold xl:text-sm-headline text-sm-regular">
                      Pricing rules (custom properties)
                    </p>
                    <Tooltip title={"Pricing rules (custom properties)"}>
                      <img
                        src={QuicstockInfo}
                        alt={"Pre"}
                        style={{ cursor: "pointer˝" }}
                      />
                    </Tooltip>
                  </div>
                  <div className="flex flex-col gap-4">
                    {product?.pricing?.pricing_rules?.custom_properties.map(
                      (cp, index) => (
                        <div key={cp.custom_property_id + index}>
                          <p className="text-left font-medium text-sm-regular mb-4">
                            {cp?.custom_property_name}
                          </p>
                          {cp?.variants?.map((cpv, jpod) => (
                            <ProductDetailsItem
                              key={jpod + cpv.variant_id}
                              label={`${cp?.custom_property_name}(${cpv.variant_name})`}
                              value={`${NAIRA}${formatCurrency(cpv.price?.toString()?.split(",").join(""))}`}
                              className={`${jpod < cp?.variants?.length - 1 ? "!mb-3" : "!mb-0"} !text-labels`}
                            />
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(product?.pricing?.pricing_rules?.bulk_pricing?.length ?? 0) >
            0 ? (
              <div className="w-full border-[0.5px] border-[#E4E7EC] rounded-[12px] p-6">
                <div className="w-full">
                  <p className="font-semibold xl:text-sm-headline text-sm-regular mb-4">
                    Bulk pricing
                  </p>
                  {product?.pricing?.pricing_rules?.bulk_pricing.map(
                    (bp, index) => (
                      <div key={index + "bpbp"}>
                        <ProductDetailsItem
                          label={"Min quantity"}
                          value={`${formatCurrency((bp?.min_quantity ?? 0)?.toString())}`}
                          className="!mb-2 !text-labels"
                        />
                        <ProductDetailsItem
                          label={"Price"}
                          value={`${NAIRA}${formatCurrency((bp?.price ?? 0)?.toString())}`}
                          className="!mb-0 !text-labels"
                        />
                        {index <
                          (product?.pricing?.pricing_rules?.bulk_pricing
                            ?.length ?? 0) -
                            1 && (
                          <div className="border-t-[1px] border-[#F6F6F6] my-4"></div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="w-full mb-4">
              <div className="p-6 border-[0.5px] border-[#E4E7EC] rounded-[12px]">
                <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                  Location
                </p>
                {(product?.locations?.bulk &&
                  product?.locations?.bulk?.length > 0) ||
                (product?.locations?.sales &&
                  product?.locations?.sales?.length > 0) ||
                (product?.locations?.online &&
                  product?.locations?.online?.length > 0) ? (
                  <>
                    {product?.locations?.bulk &&
                      product?.locations?.bulk?.length > 0 && (
                        <ProductLocation
                          title={"Bulk"}
                          locations={product?.locations?.bulk}
                        />
                      )}
                    {product?.locations?.sales &&
                      product?.locations?.sales?.length > 0 && (
                        <ProductLocation
                          title={"Sales"}
                          locations={product?.locations?.sales}
                        />
                      )}
                    {product?.locations?.online &&
                      product?.locations?.online?.length > 0 && (
                        <ProductLocation
                          title={"Online Sales"}
                          locations={product?.locations?.online}
                        />
                      )}
                  </>
                ) : (
                  <p className="text-labels">No location</p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-6 col sm:mt-0 mt-4">
            <div className="w-full border-[0.5px] border-[#E4E7EC] rounded-[12px] p-6 mb-4">
              <div className="w-full">
                <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                  Custom properties
                </p>
                {product?.custom_properties &&
                product?.custom_properties?.length > 0 ? (
                  product?.custom_properties?.map((cp, index) => (
                    <div key={index + "cp"}>
                      {cp?.values?.map((cpv, jbox) => (
                        <div key={jbox + "cpv"}>
                          <div className="border-[0.5px] border-[#E4E7EC] rounded-[12px] w-full p-3 mb-4">
                            <div className="flex justify-between text-sm-regular">
                              <p className="text-[#667185]">
                                {cp?.property_name}
                              </p>
                              <p className="text-[#344054]">{cpv?.value}</p>
                            </div>
                            <p className="text-sm-regular text-[#667185] mt-3 mb-5">
                              This property is attached to the following orders:
                            </p>
                            {!cpv?.is_global ? (
                              <div className="flex flex-wrap gap-2 mt-3">
                                {cpv.po_numbers.map((cpvpo) => (
                                  <ProductOrder key={cpvpo} orderNo={cpvpo} />
                                ))}
                              </div>
                            ) : (
                              <div className="flex flex-wrap gap-2 mt-3">
                                <div className="text-secondary-500 bg-secondary-100 rounded-[32px] py-1 px-3 xl:text-sm-regular text-labels font-medium">
                                  Global
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <p className="text-labels">No custom properties</p>
                )}
              </div>
            </div>
            <div className="w-full mb-4">
              <div className="p-4 border-[0.5px] border-[#E4E7EC] rounded-[12px]">
                <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                  Purchase orders
                </p>
                {product?.purchase_orders &&
                product?.purchase_orders?.length > 0 ? (
                  product?.purchase_orders?.map((po, index) => (
                    <ProductPurchaseOrder
                      key={po?.po_number}
                      po={po}
                      size={product?.purchase_orders?.length ?? 0}
                      index={index}
                    />
                  ))
                ) : (
                  <p className="text-labels">No purchase odrers</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit selling details */}
      <AppDrawer
        open={openEditSellingDetails}
        toggle={() => {
          setActiveStep(0);
          toggleEditSellingDetails();
        }}
        title={"Pricing"}
        subtitle={""}
      >
        <EditSellingDetails
          submit={handleSaveSellingDetails}
          goBack={() => setActiveStep(0)}
          complete={handleSubmitSellingDetails}
          cancel={toggleEditSellingDetails}
          activeStep={activeStep}
          retailPrice={retailPrice}
        />
      </AppDrawer>
      {/* Edit selling details */}

      {/* Manage custum properties */}
      <AppDrawer
        open={openManageCustomProperties}
        toggle={toggleManageCustomProperties}
        title={"Custom properties"}
        subtitle={"Manage Custom properties"}
      >
        <ManageCustomProperties
          cancel={toggleManageCustomProperties}
          addNewProperty={handleAddNewProperty}
          save={handleSaveProperties}
        />
      </AppDrawer>
      {/* Manage custum properties */}

      {/* Add custom properties */}
      <AppDrawer
        open={openAddCustomProperties}
        toggle={toggleAddCustomProperties}
        title={"Custom properties"}
        subtitle={"Add Custom properties"}
      >
        <AddCustomProperties
          goBack={handleGoBackToManageCustomProperties}
          save={handleSaveCustomProperties}
        />
      </AppDrawer>
      {/* Add custom properties */}

      {/* Edit product details */}
      <AppDrawer
        open={openEditProductDetailsDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleEditProductDetailsDrawer();
        }}
        title={"Product details"}
        subtitle={"Edit your product details and unit of measurement"}
      >
        <EditProduct
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleEditProductDetailsDrawer}
          activeStep={activeStep}
          product={product}
          productDetails={productDetails}
        />
      </AppDrawer>
      {/* Edit product details */}
    </div>
  );
};

export default ProductDetails;
