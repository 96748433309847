import React, { FC, useState } from "react";
import "../../styles.scss";
import { Select } from "antd";
import QuicstockMovingProduct from "../../../../assets/imgs/quicstock-moving-product.png";

const SlowMovingProducts: FC = () => {
  const [option, setOption] = useState<string>("");

  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-regular font-medium">Slow moving products</p>
        <Select
          placeholder={"Select option"}
          value={option || null}
          className={"overview-field !w-fit"}
          onChange={(e: any) => {
            setOption(e);
          }}
          options={[{ value: "ALL", label: "All" }]}
        />
      </div>
      <div className="w-full">
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index + "hjhssh"}
            className="mt-4 border-b-[1px] border-dark-200 pb-2"
          >
            <div className="flex gap-x-2 items-center">
              <img
                src={QuicstockMovingProduct}
                alt="QuicstockMovingProduct"
                className="rounded-full h-[24px] w-[24px]"
              />
              <p className="text-labels">Cocacola</p>
            </div>
            <div className="mt-2 flex justify-between items-center">
              <p className="text-labels">
                <span className="text-[#818181]">Threshold:</span>{" "}
                <span>5</span>
              </p>
              <p className="text-labels">
                <span className="text-[#818181]">Threshold:</span>{" "}
                <span>5</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { SlowMovingProducts };
