import React, { FC } from "react";
import "../../styles.scss";
import { IProductPurchaseOrder } from "../../model";
import { formatCurrency, NAIRA } from "../../../../utils/constants";

const ProductPurchaseOrder: FC<IProductPurchaseOrder> = ({
  po,
  size,
  index,
}) => {
  return (
    <div
      className={`w-full border-[1px] border-[#E4E7EC] rounded-[12px] p-3 ${index < size - 1 ? "mb-4" : ""}`}
    >
      <p className="xl:text-sm-regular text-labels font-medium">
        <span className="font-medium">Purchase Order</span>{" "}
        <span className="font-medium ml-1">{po?.po_number}</span>
      </p>

      <div className="w-full mt-4 xl:text-sm-regular text-labels">
        <div className="flex justify-between">
          <p className="text-[#667185]">Quantity</p>
          <p className="text-black">{po?.quantity}</p>
        </div>
        <div className="flex justify-between mt-3">
          <p className="text-[#667185]">Unit price</p>
          <span className="text-black">{`${NAIRA}${formatCurrency(po?.unit_price?.toString())}`}</span>
        </div>
      </div>
    </div>
  );
};

export { ProductPurchaseOrder };
