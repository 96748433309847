import React, { FC } from "react";
import "../../styles.scss";
import { IUploadProductsPreview } from "../../model";
import { Table } from "antd";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const UploadProductsPreview: FC<IUploadProductsPreview> = ({
  cancel,
  complete,
  importedProducts,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const columns = [
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "Retail price",
      dataIndex: "retail_price",
      key: "retail_price",
    },
  ];

  const data =
    importedProducts &&
    importedProducts?.length > 0 &&
    importedProducts?.map((product, index) => {
      return {
        key: index,
        name: product.name ?? "---",
        upc: product?.upc ?? "---",
        brand: product?.brand ?? "---",
        category: product?.category ?? "---",
        weight: product?.weight ?? "---",
        color: product?.color ?? "---",
        retail_price: product?.retail_price ?? "---",
      };
    });
  return (
    <div className="w-full flex flex-col bg-white overflow-hidden mt-6 h-full">
      <div className="flex-1 overflow-auto min-h-0 max-h-[calc(100vh-470px)] sm:max-h-[calc(100vh-380px)] md:max-h-[calc(100vh-470px)] lg:max-h-[calc(100vh-475px)]">
        <Table
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"p-table"}
          pagination={false}
        />
      </div>
      <div className="w-full px-8">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid grid-cols-3 gap-x-2"}>
          <div className={"col-1"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Cancel"}
              type={"button"}
              onClick={cancel}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-0"}
              state={ButtonState.PRIMARY}
              text={"Import details"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { UploadProductsPreview };
