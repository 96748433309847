import React, { FC } from "react";
import "../../styles.scss";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { ReactComponent as QuicstockQuickLink } from "../../../../assets/svgs/quicstock-quick-link.svg";

const QuickLinks: FC = () => {
  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-headline font-medium">Quick links</p>
        <Button
          css={"!px-5 !py-2 !rounded-[6px] !rounded-[6px] !text-labels"}
          state={ButtonState.PRIMARY}
          text={"View all"}
          type={"button"}
        />
      </div>
      <div className="w-full">
        <div className="border-[1px] border-[#F7F7F7] rounded-[6px] p-4 flex items-center justify-between bg-dark-50 mt-4">
          <div>
            <p className="text-sm-regular font-medium">Stockouts</p>
            <p className="text-labels mt-2">
              Stay ahead with timely re-order reminders
            </p>
          </div>
          <QuicstockQuickLink />
        </div>
        <div className="border-[1px] border-[#F7F7F7] rounded-[6px] p-4 flex items-center justify-between bg-dark-50 mt-4">
          <div>
            <p className="text-sm-regular font-medium">Restock alerts</p>
            <p className="text-labels mt-2">Never run low on stock</p>
          </div>
          <QuicstockQuickLink />
        </div>
        <div className="border-[1px] border-[#F7F7F7] rounded-[6px] p-4 flex items-center justify-between bg-dark-50 mt-4">
          <div>
            <p className="text-sm-regular font-medium">Out of Stock Products</p>
            <p className="text-labels mt-2">
              Track unavailable items and restock efficiently
            </p>
          </div>
          <QuicstockQuickLink />
        </div>
        <div className="border-[1px] border-[#F7F7F7] rounded-[6px] p-4 flex items-center justify-between bg-dark-50 mt-4">
          <div>
            <p className="text-sm-regular font-medium">Deliveries due</p>
            <p className="text-labels mt-2">
              Keep tabs on upcoming deliveries with ease
            </p>
          </div>
          <QuicstockQuickLink />
        </div>
      </div>
    </div>
  );
};

export { QuickLinks };
