import { ThunkAction } from "redux-thunk";
import {
  createAlert,
  deleteAlert,
  getReorderAlerts,
  getRestockAlerts,
  updateAlert,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { AlertItemModel } from "../../model/alerts.model";

export const Types = {
  CREATE_ALERT_START: "CREATE_ALERT_START",
  CREATE_ALERT_SUCCESS: "CREATE_ALERT_SUCCESS",
  CREATE_ALERT_END: "CREATE_ALERT_END",

  FETCH_RESTOCK_ALERT_START: "FETCH_RESTOCK_ALERT_START",
  FETCH_RESTOCK_ALERT_SUCCESS: "FETCH_RESTOCK_ALERT_SUCCESS",
  FETCH_RESTOCK_ALERT_END: "FETCH_RESTOCK_ALERT_END",

  FETCH_REORDER_ALERT_START: "FETCH_REORDER_ALERT_START",
  FETCH_REORDER_ALERT_SUCCESS: "FETCH_REORDER_ALERT_SUCCESS",
  FETCH_REORDER_ALERT_END: "FETCH_REORDER_ALERT_END",

  UPDATE_ALERT_START: "UPDATE_ALERT_START",
  UPDATE_ALERT_SUCCESS: "UPDATE_ALERT_SUCCESS",
  UPDATE_ALERT_END: "UPDATE_ALERT_END",

  DELETE_ALERT_START: "DELETE_ALERT_START",
  DELETE_ALERT_SUCCESS: "DELETE_ALERT_SUCCESS",
  DELETE_ALERT_END: "DELETE_ALERT_END",
};

export const CreateAlert = (
  payload: AlertItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_ALERT_START });
    return new Promise((resolve, reject) => {
      createAlert(payload)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_ALERT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_ALERT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchRestockAlerts = (): ThunkAction<
  Promise<any>,
  RootState,
  unknown,
  Action
> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_RESTOCK_ALERT_START });
    return new Promise((resolve, reject) => {
      getRestockAlerts()
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_ALERT_SUCCESS,
            restockAlerts: data.data?.restockAlerts,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_ALERT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchReorderAlerts = (): ThunkAction<
  Promise<any>,
  RootState,
  unknown,
  Action
> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_REORDER_ALERT_START });
    return new Promise((resolve, reject) => {
      getReorderAlerts()
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_REORDER_ALERT_SUCCESS,
            reorderAlerts: data.data?.reorderAlerts,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_REORDER_ALERT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const UpdateAlert = (
  payload: AlertItemModel,
  alertId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_ALERT_START });
    return new Promise((resolve, reject) => {
      updateAlert(payload, alertId)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_ALERT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_ALERT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const DeleteAlert = (
  alertId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_ALERT_START });
    return new Promise((resolve, reject) => {
      deleteAlert(alertId)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_ALERT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_ALERT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};
