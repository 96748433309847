import React, { FC, useState } from "react";
import "../../styles.scss";
import { IImportProducts } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { UploadProducts } from "./UploadProducts";
import { UploadProductsPreview } from "./UploadProductsPreview";
import { UploadProgressIndicator } from "../../../../components/UploadProgressIndicator";
import Papa from "papaparse";
import openNotification from "../../../../components/Notification/notification";
import NotificationMessage from "../../../../components/Notification";
import { ProductItemModel } from "../../../../model/product.model";

const ImportProducts: FC<IImportProducts> = ({
  submit,
  complete,
  cancel,
  cancelUpload,
  activeStep,
  importedProducts,
  uploadProgressPercentage,
}) => {
  const [size, setSize] = useState<string>("");
  const handleFileUpload = (file: any, size: string) => {
    const reader = new FileReader();

    if (!(file instanceof Blob)) {
      openNotification(
        <NotificationMessage
          type="error"
          title="Error"
          message={"File is not valid."}
        />,
        true
      );
      return;
    }

    reader.onload = (event) => {
      const csvData: any = event?.target?.result;
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setSize(size);
          submit(result.data as ProductItemModel[], file);
        },
        error: (error) => {
          openNotification(
            <NotificationMessage
              type="error"
              title="Error"
              message={"Error parsing CSV file"}
            />,
            true
          );
        },
      });
    };
    reader.readAsText(file);
  };

  const downloadSample = () => {
    fetch(`${process.env.PUBLIC_URL}/files/sample_prods.csv`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sample_prods.csv");
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Download failed"
            message={error}
          />,
          true
        );
      });
  };

  return (
    <div className="w-full flex flex-col">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Import data"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10 flex-1">
        <div className="flex flex-col">
          <div className="w-full px-10">
            <UploadProgressIndicator
              state={activeStep === 0 ? "DEFAULT" : "UPLOADING"}
              defaultStateText={"Template"}
              uploadingStateText={"Data Importation"}
              uploadType={"CSV"}
              size={size}
              cancel={cancelUpload}
              percent={uploadProgressPercentage}
              download={downloadSample}
            />
          </div>
          <div className="flex-1" hidden={activeStep !== 0}>
            <UploadProducts submit={handleFileUpload} />
          </div>
          <div className="flex-1" hidden={activeStep === 0 || uploadProgressPercentage < 100}>
            <UploadProductsPreview
              cancel={cancel}
              complete={complete}
              importedProducts={importedProducts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ImportProducts };
