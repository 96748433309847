/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import "../../styles.scss";
import { IViewCustomProperty } from "../../model";
import { ReactComponent as QuicstockEdit } from "../../../../assets/svgs/quicstock-edit.svg";
import { ReactComponent as QuicstockDelete } from "../../../../assets/svgs/quicstock-delete.svg";
import { ReactComponent as QuicstockAddDeep } from "../../../../assets/svgs/quicstock-add-deep.svg";

const ViewCustomProperty: FC<IViewCustomProperty> = ({
  editCp,
  deleteCp,
  add,
  cpDetails,
}) => {
  return (
    <div className="w-full h-full">
      {cpDetails.custom_properties.map((cpd) => (
        <div className="border-[1px] mt-6 rounded-[12px] p-4 border-[#E4E7EC]">
          <div
            key={cpd.custom_property_id}
            className="flex justify-between items-center"
          >
            <p className="text-sm-regular font-semibold">
              {cpd.custom_property_name}
            </p>
            <div className="flex gap-x-3 items-center">
              <button onClick={() => editCp(cpd)}>
                <QuicstockEdit className="sm:h-[36px] sm:w-[36px] h-[30px] w-[30px]" />
              </button>
              <button onClick={() => deleteCp(cpd)}>
                <QuicstockDelete className="sm:h-[36px] sm:w-[36px] h-[30px] w-[30px]" />
              </button>
            </div>
          </div>
          <p className="mt-3 text-[#667185]">
            {cpd.custom_property_values.length} variants
          </p>
        </div>
      ))}
      <div className="flex justify-end">
        <button
          type="button"
          onClick={add}
          className="flex items-center gap-x-2 px-3 py-2 mt-4 bg-[#F0F2F5] rounded-[10px] font-medium text-labels"
        >
          <QuicstockAddDeep /> Add new custom property
        </button>
      </div>
    </div>
  );
};

export { ViewCustomProperty };
