/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ISellingDetailsForm } from "../../model";
import { Checkbox, Form, Input, Radio, Tooltip } from "antd";
import { Field, FieldArray, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import QuicstockNaira from "../../../../assets/svgs/quicstock-naira.svg";
import { SellingRetailPriceModel } from "../../../../model/product.model";
import QuicstockInfo from "../../../../assets/svgs/quicstock-info.svg";
import { ReactComponent as QuicstockAdd } from "../../../../assets/svgs/quicstock-add.svg";
import { ReactComponent as QuicstockMinusDeep } from "../../../../assets/svgs/quicstock-minu-deep.svg";

const SellingDetailsForm: FC<ISellingDetailsForm> = ({ cancel, submit }) => {
  const product = useSelector((state: RootState) => state.products.product);
  const [values, setValues] = useState<SellingRetailPriceModel>({
    averageCostPrice: "",
    costPrice: "",
    sellingPrice: "",
    mode: "",
    discountMode: "",
    percentage: "",
    amount: "",
    discountPercentage: "",
    discountAmount: "",
    includeVat: false,
    pricing_rules: {
      custom_properties: [],
      bulk_pricing: [],
      discount: "",
    },
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    // if (values.mode === "0") {
    //   if (
    //     !values.percentage ||
    //     parseFloat(values.percentage.toString()) === 0
    //   ) {
    //     openNotification(
    //       <NotificationMessage
    //         type="error"
    //         title="Error"
    //         message="Enter a valid percentage"
    //       />,
    //       true
    //     );
    //     return;
    //   }
    // }
    // if (values.mode === "1") {
    //   if (
    //     !values.amount ||
    //     parseFloat(values.amount.split(",").join("").toString()) === 0
    //   ) {
    //     openNotification(
    //       <NotificationMessage
    //         type="error"
    //         title="Error"
    //         message="Enter a valid amount"
    //       />,
    //       true
    //     );
    //     return;
    //   }
    // }
    submit(values);
  };

  useEffect(() => {
    if (product) {
      const formattedCustomProperties =
        product.custom_properties?.map((cp) => ({
          custom_property_id: cp?.id ?? "",
          custom_property_name: cp?.property_name ?? "",
          variants:
            cp?.values?.map((cpv) => {
              const existingVariant =
                product.pricing?.pricing_rules?.custom_properties
                  ?.flatMap((prop) => prop.variants)
                  ?.find((variant) => variant?.variant_id === cpv.id);

              return {
                variant_id: cpv?.id ?? "",
                variant_name: cpv?.value ?? "",
                variant_display_name: `${cp?.property_name}(${cpv?.value})`,
                price: existingVariant?.price
                  ? formatCurrency(existingVariant.price.toString())
                  : "",
              };
            }) ?? [],
        })) ?? [];

      let formattedBulkPricing =
        product.pricing?.pricing_rules?.bulk_pricing?.map((bp) => ({
          min_quantity: bp.min_quantity?.toString() ?? "",
          price: bp.price ? formatCurrency(bp.price.toString()) : "",
        })) ?? [];

      if (formattedBulkPricing.length === 0) {
        formattedBulkPricing = [{ min_quantity: "", price: "" }];
      }

      const formValues: SellingRetailPriceModel = {
        ...values,
        averageCostPrice: formatCurrency(
          product.pricing?.average_cost_price?.toString() ?? ""
        ),
        costPrice: formatCurrency(
          product.pricing?.retail_price?.toString() ?? ""
        ),
        sellingPrice: formatCurrency(
          product.pricing?.retail_price?.toString() ?? ""
        ),
        pricing_rules: {
          custom_properties: formattedCustomProperties,
          bulk_pricing: formattedBulkPricing,
          discount: (product.pricing?.discount ?? 0)?.toString(),
        },
      };

      setValues(formValues);
    }
  }, [product]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => {
        const computeMarkup = (
          type: "PERCENT" | "AMOUNT" | "",
          value: string,
          includeVat: boolean
        ) => {
          const basePrice = parseFloat(
            (product.pricing?.retail_price ?? 0)?.toString()
          );

          const vatAmount = basePrice * (7.2 / 100);
          const vatInclusivePrice = basePrice + vatAmount;

          let computedValue = includeVat ? vatInclusivePrice : basePrice;

          if (value && parseFloat(value) > 0) {
            if (type === "PERCENT") {
              const markupPercentage = parseFloat(value) / 100;
              computedValue += markupPercentage * computedValue;
            } else if (type === "AMOUNT") {
              const markupAmount = parseFloat(value.split(",").join(""));
              computedValue += markupAmount;
            }
          }

          computedValue = parseFloat(computedValue.toFixed(2));

          setFieldValue(
            "sellingPrice",
            formatCurrency(computedValue.toString())
          );

          computeDiscount(
            values.discountMode === "0"
              ? "PERCENT"
              : values.discountMode === "1"
                ? "AMOUNT"
                : "",
            values.discountMode === "0"
              ? values.discountPercentage
              : values.discountMode === "1"
                ? values.discountAmount
                : "0",
            computedValue.toString()
          );
        };

        const computeDiscount = (
          type: "PERCENT" | "AMOUNT" | "",
          value: string,
          sellingPrice?: string
        ) => {
          let discountPrice = sellingPrice
            ? parseFloat(sellingPrice?.split(",").join(""))
            : parseFloat(values?.sellingPrice?.split(",").join(""));

          if (value && parseFloat(value) > 0) {
            if (type === "PERCENT") {
              const discountPercentage = parseFloat(value) / 100;
              discountPrice = discountPercentage * discountPrice;
            } else if (type === "AMOUNT") {
              const discountAmount = parseFloat(value.split(",").join(""));
              discountPrice = discountAmount;
            }
          } else {
            discountPrice = parseFloat(
              (product.pricing?.discount ?? 0).toString()
            );
          }

          discountPrice = parseFloat(discountPrice.toFixed(2));

          setFieldValue(
            "pricing_rules.discount",
            formatCurrency(discountPrice.toString())
          );
        };

        const addItem = () => {
          const newItem = {
            min_quantity: "",
            price: "",
          };
          const updatedItems = [...values.pricing_rules.bulk_pricing, newItem];
          setFieldValue(`pricing_rules.bulk_pricing`, updatedItems);
        };

        const removeItem = (index: number) => {
          const updatedItems = values.pricing_rules.bulk_pricing.filter(
            (_, i) => i !== index
          );
          setFieldValue(`pricing_rules.bulk_pricing`, updatedItems);
        };

        return (
          <Form onFinish={handleSubmit} form={form}>
            <div className={`w-full drawer-content-height`}>
              <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4 mb-4">
                <p className="font-semibold xl:text-sm-headline text-sm-regular mb-5">
                  General
                </p>
                <div className={"mb-2"}>
                  <div className="flex justify-between items-center">
                    <div>
                      <FieldName
                        name={"Average Cost Price"}
                        className="!text-black font-medium"
                      />
                    </div>
                    <p className="text-sm-headline font-semibold">
                      {NAIRA}
                      {formatCurrency(
                        product.pricing?.average_cost_price?.toString()
                      )}
                    </p>
                  </div>
                </div>
                <div className={"mb-5"}>
                  <div className="flex justify-between items-center">
                    <div>
                      <FieldName
                        name={"Current Cost Price"}
                        className="!text-black font-medium"
                      />
                    </div>
                    <p className="text-sm-headline font-semibold">
                      {NAIRA}
                      {values?.costPrice}
                    </p>
                  </div>
                </div>
                <div className={"mb-5"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <FieldName
                      name={"Markup options:"}
                      className="!text-black font-medium"
                    />
                    <Form.Item
                      name={"mode"}
                      help={touched.mode && errors.mode ? errors.mode : ""}
                      validateStatus={
                        touched.mode && errors.mode ? "error" : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <Radio.Group
                            style={{
                              fontSize: "16px",
                              display: "flex",
                            }}
                            value={values?.mode || ""}
                            onChange={(e) => {
                              setFieldValue("mode", e.target.value);
                              computeMarkup(
                                e.target.value === "0"
                                  ? "PERCENT"
                                  : e.target.value === "1"
                                    ? "AMOUNT"
                                    : "",
                                e.target.value === "0"
                                  ? values.percentage
                                  : e.target.value === "1"
                                    ? values.amount
                                    : "0",
                                values.includeVat
                              );
                              setFieldValue("amount", "");
                              setFieldValue("percentage", "");
                            }}
                          >
                            <div className="grid grid-cols-12">
                              <div className="col-span-5">
                                <Radio value="0">
                                  <div className="flex items-center gap-x-2">
                                    <p className="text-gray-300">Percentage</p>
                                    <Form.Item
                                      name={"percentage"}
                                      help={
                                        touched.percentage && errors.percentage
                                          ? errors.percentage
                                          : ""
                                      }
                                      validateStatus={
                                        touched.percentage && errors.percentage
                                          ? "error"
                                          : "success"
                                      }
                                      className="mb-0"
                                    >
                                      <Field>
                                        {({ form: { setFieldValue } }: any) => (
                                          <Input
                                            type={"number"}
                                            className={
                                              "form-field_input_2 slim"
                                            }
                                            value={values?.percentage || ""}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "percentage",
                                                e.target.value
                                              );
                                              computeMarkup(
                                                "PERCENT",
                                                e.target.value,
                                                values.includeVat
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setFieldValue(
                                                "percentage",
                                                e.target.value
                                              );
                                            }}
                                            placeholder="0%"
                                            disabled={values.mode !== "0"}
                                          />
                                        )}
                                      </Field>
                                    </Form.Item>
                                  </div>
                                </Radio>
                              </div>
                              <div className="col-span-5">
                                <Radio value="1">
                                  <div className="flex items-center gap-x-2">
                                    <p className="text-gray-300">Amount</p>
                                    <FieldContainer
                                      iconPlacementCss={"smallPricingFieldIcon"}
                                      icon={QuicstockNaira}
                                    >
                                      <Form.Item
                                        name={"amount"}
                                        help={
                                          touched.amount && errors.amount
                                            ? errors.amount
                                            : ""
                                        }
                                        validateStatus={
                                          touched.amount && errors.amount
                                            ? "error"
                                            : "success"
                                        }
                                        className="mb-0"
                                      >
                                        <Field>
                                          {({
                                            form: { setFieldValue },
                                          }: any) => (
                                            <Input
                                              type={"text"}
                                              className={
                                                "form-field_input_2 extra slim"
                                              }
                                              value={values?.amount || ""}
                                              onChange={(e) => {
                                                const inputValue =
                                                  e.target.value;
                                                const formattedValue =
                                                  formatCurrency(inputValue);
                                                setFieldValue(
                                                  "amount",
                                                  formattedValue
                                                );
                                                computeMarkup(
                                                  "AMOUNT",
                                                  formattedValue,
                                                  values.includeVat
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setFieldValue(
                                                  "amount",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="0.00"
                                              disabled={values.mode !== "1"}
                                            />
                                          )}
                                        </Field>
                                      </Form.Item>
                                    </FieldContainer>
                                  </div>
                                </Radio>
                              </div>
                            </div>
                          </Radio.Group>
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-5"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <FieldName
                      name={"Discount options:"}
                      className="!text-black font-medium"
                    />
                    <Form.Item
                      name={"discountMode"}
                      help={
                        touched.discountMode && errors.discountMode
                          ? errors.discountMode
                          : ""
                      }
                      validateStatus={
                        touched.discountMode && errors.discountMode
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <Radio.Group
                            style={{
                              fontSize: "16px",
                              display: "flex",
                            }}
                            value={values?.discountMode || ""}
                            onChange={(e) => {
                              setFieldValue("discountMode", e.target.value);
                              computeDiscount(
                                e.target.value === "0"
                                  ? "PERCENT"
                                  : e.target.value === "1"
                                    ? "AMOUNT"
                                    : "",
                                e.target.value === "0"
                                  ? values.discountPercentage
                                  : e.target.value === "1"
                                    ? values.discountAmount
                                    : "0"
                              );
                              setFieldValue("discountAmount", "");
                              setFieldValue("discountPercentage", "");
                            }}
                          >
                            <div className="grid grid-cols-12">
                              <div className="col-span-5">
                                <Radio value="0">
                                  <div className="flex items-center gap-x-2">
                                    <p className="text-gray-300">Percentage</p>
                                    <Form.Item
                                      name={"discountPercentage"}
                                      help={
                                        touched.discountPercentage &&
                                        errors.discountPercentage
                                          ? errors.discountPercentage
                                          : ""
                                      }
                                      validateStatus={
                                        touched.discountPercentage &&
                                        errors.discountPercentage
                                          ? "error"
                                          : "success"
                                      }
                                      className="mb-0"
                                    >
                                      <Field>
                                        {({ form: { setFieldValue } }: any) => (
                                          <Input
                                            type={"number"}
                                            className={
                                              "form-field_input_2 slim"
                                            }
                                            value={
                                              values?.discountPercentage || ""
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "discountPercentage",
                                                e.target.value
                                              );
                                              computeDiscount(
                                                "PERCENT",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setFieldValue(
                                                "discountPercentage",
                                                e.target.value
                                              );
                                            }}
                                            placeholder="0%"
                                            disabled={
                                              values.discountMode !== "0"
                                            }
                                          />
                                        )}
                                      </Field>
                                    </Form.Item>
                                  </div>
                                </Radio>
                              </div>
                              <div className="col-span-5">
                                <Radio value="1">
                                  <div className="flex items-center gap-x-2">
                                    <p className="text-gray-300">Amount</p>
                                    <FieldContainer
                                      iconPlacementCss={"smallPricingFieldIcon"}
                                      icon={QuicstockNaira}
                                    >
                                      <Form.Item
                                        name={"discountAmount"}
                                        help={
                                          touched.discountAmount &&
                                          errors.discountAmount
                                            ? errors.discountAmount
                                            : ""
                                        }
                                        validateStatus={
                                          touched.discountAmount &&
                                          errors.discountAmount
                                            ? "error"
                                            : "success"
                                        }
                                        className="mb-0"
                                      >
                                        <Field>
                                          {({
                                            form: { setFieldValue },
                                          }: any) => (
                                            <Input
                                              type={"text"}
                                              className={
                                                "form-field_input_2 extra slim"
                                              }
                                              value={
                                                values?.discountAmount || ""
                                              }
                                              onChange={(e) => {
                                                const inputValue =
                                                  e.target.value;
                                                const formattedValue =
                                                  formatCurrency(inputValue);
                                                setFieldValue(
                                                  "discountAmount",
                                                  formattedValue
                                                );
                                                computeDiscount(
                                                  "AMOUNT",
                                                  formattedValue
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setFieldValue(
                                                  "discountAmount",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="0.00"
                                              disabled={
                                                values.discountMode !== "1"
                                              }
                                            />
                                          )}
                                        </Field>
                                      </Form.Item>
                                    </FieldContainer>
                                  </div>
                                </Radio>
                              </div>
                            </div>
                          </Radio.Group>
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-2"}>
                  <div className="flex justify-between items-center">
                    <div>
                      <FieldName
                        name={"Updated Cost Price"}
                        className="!text-black font-medium"
                      />
                      {values?.includeVat ? (
                        <p className="text-sm-regular text-secondary-500">
                          Note: Selling price is inclusive of 7.2% VAT
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className="text-sm-headline font-semibold">
                      {NAIRA}
                      {values?.sellingPrice}
                    </p>
                  </div>
                </div>
                <div className={"vat-entry"}>
                  <FieldContainer iconPlacementCss={"pricingFieldIcon"}>
                    <Form.Item
                      name={"includeVat"}
                      help={
                        touched.includeVat && errors.includeVat
                          ? errors.includeVat
                          : ""
                      }
                      validateStatus={
                        touched.includeVat && errors.includeVat
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <Checkbox
                            className="pricing-checkbox"
                            value={values.includeVat}
                            onChange={(checkedValues) => {
                              const includeVat = checkedValues.target.checked;
                              setFieldValue("includeVat", includeVat);
                              computeMarkup(
                                values.mode === "0"
                                  ? "PERCENT"
                                  : values.mode === "1"
                                    ? "AMOUNT"
                                    : "",
                                values.mode === "0"
                                  ? values.percentage
                                  : values.mode === "1"
                                    ? values.amount
                                    : "0",
                                includeVat
                              );
                            }}
                          >
                            <span className="pricing-label">Include VAT </span>
                          </Checkbox>
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mt-2"}>
                  <div className="flex justify-between items-center">
                    <div>
                      <FieldName
                        name={"Discount"}
                        className="!text-black font-medium"
                      />
                    </div>
                    <p className="text-sm-headline font-semibold">
                      {NAIRA}
                      {formatCurrency(
                        values?.pricing_rules?.discount
                          ? values?.pricing_rules?.discount
                          : "0"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              {values.pricing_rules.custom_properties?.length > 0 ? (
                <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4 mb-4">
                  <div className="flex items-center mb-4 gap-x-1">
                    <p className="font-semibold xl:text-sm-headline text-sm-regular">
                      Pricing rules (custom properties)
                    </p>
                    <Tooltip title={"Pricing rules (custom properties)"}>
                      <img
                        src={QuicstockInfo}
                        alt={"Pre"}
                        style={{ cursor: "pointer˝" }}
                      />
                    </Tooltip>
                  </div>
                  <FieldArray name="custom_properties">
                    {({ push, remove }) => (
                      <>
                        {values.pricing_rules.custom_properties.map(
                          (item, index) => (
                            <div key={index + "index"} className="mb-4">
                              <FieldContainer iconPlacementCss={"iconCss"}>
                                <FieldName
                                  name={item.custom_property_name}
                                  className="!text-black font-medium"
                                />
                                <div className="grid grid-cols-2 items-center gap-x-6 gap-y-4">
                                  <FieldArray name="variants">
                                    {({ push, remove }) => (
                                      <>
                                        {values.pricing_rules.custom_properties[
                                          index
                                        ].variants.map((vt, jpod) => (
                                          <div
                                            key={"vt" + jpod}
                                            className="flex items-center gap-x-4"
                                          >
                                            <p className="text-gray-300 text-labels">
                                              {vt?.variant_display_name}
                                            </p>
                                            <FieldContainer
                                              iconPlacementCss={
                                                "smallPricingFieldIcon"
                                              }
                                              icon={QuicstockNaira}
                                            >
                                              <Form.Item
                                                name={`pricing_rules.custom_properties[${index}].variants[${jpod}].price`}
                                                help={
                                                  touched.pricing_rules
                                                    ?.custom_properties?.[index]
                                                    ?.variants?.[jpod]?.price &&
                                                  (errors as any)?.pricing_rules
                                                    ?.custom_properties?.[index]
                                                    ?.variants?.[jpod]?.price
                                                    ? (errors as any)
                                                        ?.pricing_rules
                                                        ?.custom_properties?.[
                                                        index
                                                      ]?.variants?.[jpod]?.price
                                                    : ""
                                                }
                                                validateStatus={
                                                  touched.pricing_rules
                                                    ?.custom_properties?.[index]
                                                    ?.variants?.[jpod]?.price &&
                                                  (errors as any)?.pricing_rules
                                                    ?.custom_properties?.[index]
                                                    ?.variants?.[jpod]?.price
                                                    ? "error"
                                                    : "success"
                                                }
                                                className="mb-0"
                                              >
                                                <Field>
                                                  {({
                                                    form: { setFieldValue },
                                                  }: any) => (
                                                    <Input
                                                      type={"text"}
                                                      className={
                                                        "form-field_input_2 extra slim"
                                                      }
                                                      value={
                                                        values?.pricing_rules
                                                          ?.custom_properties?.[
                                                          index
                                                        ]?.variants?.[jpod]
                                                          ?.price || ""
                                                      }
                                                      onChange={(e) => {
                                                        const inputValue =
                                                          e.target.value;
                                                        const formattedValue =
                                                          formatCurrency(
                                                            inputValue
                                                          );
                                                        setFieldValue(
                                                          `pricing_rules.custom_properties[${index}].variants[${jpod}].price`,
                                                          formattedValue
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        setFieldValue(
                                                          `pricing_rules.custom_properties[${index}].variants[${jpod}].price`,
                                                          e.target.value
                                                        );
                                                      }}
                                                      placeholder="0.00"
                                                    />
                                                  )}
                                                </Field>
                                              </Form.Item>
                                            </FieldContainer>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </FieldArray>
                                </div>
                              </FieldContainer>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </div>
              ) : (
                <></>
              )}

              <div className="border-[1px] rounded-[12px] border-[#E4E7EC] p-4">
                <div className="flex items-center mb-4 justify-between">
                  <p className="font-semibold xl:text-sm-headline text-sm-regular">
                    Bulk pricing
                  </p>
                  <button
                    type="button"
                    className="border-[1px] border-black rounded-full flex justify-center mt-1 items-center h-8 w-8"
                    onClick={() => addItem()}
                  >
                    <QuicstockAdd />
                  </button>
                </div>
                <FieldArray name="bulk_pricing">
                  {({ push, remove }) => (
                    <>
                      {values.pricing_rules.bulk_pricing.map((item, index) => (
                        <div
                          key={"indexpro" + index}
                          className={`grid grid-cols-12 items-center gap-4 ${index < values.pricing_rules.bulk_pricing.length - 1 ? "mb-4" : "mb-0"}`}
                        >
                          <div className="col-span-6 flex items-center gap-x-4">
                            <p className="text-gray-300 text-labels">
                              Min quantity
                            </p>
                            <div>
                              <FieldContainer iconPlacementCss={"iconCss"}>
                                <Form.Item
                                  name={`pricing_rules.bulk_pricing[${index}].min_quantity`}
                                  help={
                                    touched.pricing_rules?.bulk_pricing?.[index]
                                      ?.min_quantity &&
                                    (errors as any)?.pricing_rules
                                      ?.custom_properties?.[index]?.min_quantity
                                      ? (errors as any)?.pricing_rules
                                          ?.bulk_pricing?.[index]?.min_quantity
                                      : ""
                                  }
                                  validateStatus={
                                    touched.pricing_rules?.bulk_pricing?.[index]
                                      ?.min_quantity &&
                                    (errors as any)?.pricing_rules
                                      ?.bulk_pricing?.[index]?.min_quantity
                                      ? "error"
                                      : "success"
                                  }
                                  className="mb-0"
                                >
                                  <Field>
                                    {({ form: { setFieldValue } }: any) => (
                                      <Input
                                        type={"number"}
                                        className={"form-field_input_2 slim"}
                                        value={
                                          values?.pricing_rules?.bulk_pricing?.[
                                            index
                                          ]?.min_quantity || ""
                                        }
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          setFieldValue(
                                            `pricing_rules.bulk_pricing[${index}].min_quantity`,
                                            inputValue
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldValue(
                                            `pricing_rules.bulk_pricing[${index}].min_quantity`,
                                            e.target.value
                                          );
                                        }}
                                        placeholder="0"
                                      />
                                    )}
                                  </Field>
                                </Form.Item>
                              </FieldContainer>
                            </div>
                          </div>
                          <div
                            className={`${values.pricing_rules.bulk_pricing.length > 1 ? "col-span-5" : "col-span-6"} flex items-center gap-x-4`}
                          >
                            <p className="text-gray-300 text-labels">Price</p>
                            <FieldContainer
                              iconPlacementCss={"smallPricingFieldIcon"}
                              icon={QuicstockNaira}
                            >
                              <Form.Item
                                name={`pricing_rules.bulk_pricing[${index}].price`}
                                help={
                                  touched.pricing_rules?.bulk_pricing?.[index]
                                    ?.price &&
                                  (errors as any)?.pricing_rules
                                    ?.bulk_pricing?.[index]?.price
                                    ? (errors as any)?.pricing_rules
                                        ?.bulk_pricing?.[index]?.price
                                    : ""
                                }
                                validateStatus={
                                  touched.pricing_rules?.bulk_pricing?.[index]
                                    ?.price &&
                                  (errors as any)?.pricing_rules
                                    ?.bulk_pricing?.[index]?.price
                                    ? "error"
                                    : "success"
                                }
                                className="mb-0"
                              >
                                <Field>
                                  {({ form: { setFieldValue } }: any) => (
                                    <Input
                                      type={"text"}
                                      className={
                                        "form-field_input_2 extra slim"
                                      }
                                      value={
                                        values?.pricing_rules?.bulk_pricing?.[
                                          index
                                        ]?.price || ""
                                      }
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const formattedValue =
                                          formatCurrency(inputValue);
                                        setFieldValue(
                                          `pricing_rules.bulk_pricing[${index}].price`,
                                          formattedValue
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setFieldValue(
                                          `pricing_rules.bulk_pricing[${index}].price`,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="0.00"
                                    />
                                  )}
                                </Field>
                              </Form.Item>
                            </FieldContainer>
                          </div>
                          {values.pricing_rules.bulk_pricing.length > 1 && (
                            <div className="col-span-1 flex justify-end">
                              <button
                                type="button"
                                className="border-[1px] border-[#820909] rounded-full flex justify-center mt-1 items-center h-7 w-7"
                                onClick={() => removeItem(index)}
                              >
                                <QuicstockMinusDeep />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="w-full left-0 right-0 bottom-0 bg-white">
              <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
              <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
                <div className={"col-1 sm:block hidden"}></div>
                <div className={"col-1"}>
                  <Button
                    css={
                      "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                    }
                    state={ButtonState.PRIMARY}
                    text={"Go back"}
                    type={"button"}
                    onClick={cancel}
                  />
                </div>
                <div className={"col-1"}>
                  <Button
                    css={"w-full !px-0 !rounded-[6px]"}
                    state={ButtonState.PRIMARY}
                    text={"Preview"}
                    type={"submit"}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const validator = yup.object().shape({
  costPrice: yup.string().required("Cost price is required"),
  sellingPrice: yup.string().required("Selling price is required"),
});

export { SellingDetailsForm };
