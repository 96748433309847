import { ThunkAction } from "redux-thunk";
import {
  createSupplier,
  deleteSuppliers,
  getSuppliers,
  searchSuppliers,
  updateSupplier,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { SupplierItemModel } from "../../model/supplier.model";
import { ApiPaginationModel } from "../../model/base.model";

export const Types = {
  CREATE_SUPPLIER_START: "CREATE_SUPPLIER_START",
  CREATE_SUPPLIER_SUCCESS: "CREATE_SUPPLIER_SUCCESS",
  CREATE_SUPPLIER_END: "CREATE_SUPPLIER_END",

  FETCH_SUPPLIERS_START: "FETCH_SUPPLIERS_START",
  FETCH_SUPPLIERS_SUCCESS: "FETCH_SUPPLIERS_SUCCESS",
  FETCH_SUPPLIERS_END: "FETCH_SUPPLIERS_END",

  UPDATE_SUPPLIER_START: "UPDATE_SUPPLIER_START",
  UPDATE_SUPPLIER_SUCCESS: "UPDATE_SUPPLIER_SUCCESS",
  UPDATE_SUPPLIER_END: "UPDATE_SUPPLIER_END",

  DELETE_SUPPLIERS_START: "DELETE_SUPPLIERS_START",
  DELETE_SUPPLIERS_SUCCESS: "DELETE_SUPPLIERS_SUCCESS",
  DELETE_SUPPLIERS_END: "DELETE_SUPPLIERS_END",

  SEARCH_SUPPLIERS_START: "SEARCH_SUPPLIERS_START",
  SEARCH_SUPPLIERS_SUCCESS: "SEARCH_SUPPLIERS_SUCCESS",
  SEARCH_SUPPLIERS_END: "SEARCH_SUPPLIERS_END",
};

export const CreateSupplier = (
  req: SupplierItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_SUPPLIER_START });
    return new Promise((resolve, reject) => {
      createSupplier(req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_SUPPLIER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_SUPPLIER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchSuppliers = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_SUPPLIERS_START });
    return new Promise((resolve, reject) => {
      getSuppliers(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_SUPPLIERS_SUCCESS,
            suppliers: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_SUPPLIERS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const UpdateSupplier = (
  req: SupplierItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_SUPPLIER_START });
    return new Promise((resolve, reject) => {
      updateSupplier(req)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_SUPPLIER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_SUPPLIER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const DeleteSuppliers = (
  ids: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_SUPPLIERS_START });
    return new Promise((resolve, reject) => {
      deleteSuppliers(ids)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_SUPPLIERS_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_SUPPLIERS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const SearchSuppliers = (
  searchText: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SEARCH_SUPPLIERS_START });
    return new Promise((resolve, reject) => {
      searchSuppliers(searchText)
        .then(({ data }) => {
          dispatch({
            type: Types.SEARCH_SUPPLIERS_SUCCESS,
            supplierSearchResult: data?.data?.suppliers ?? [],
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SEARCH_SUPPLIERS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};
