import { Popover, Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllLocationProducts } from "../../model";
import { ReactComponent as QuicstockMore } from "../../../../assets/svgs/quicstock-more.svg";
import { ReactComponent as QuicstockTransfer } from "../../../../assets/svgs/quicstock-box.svg";
import { ReactComponent as QuicstockFolderCross } from "../../../../assets/svgs/quicstock-folder-cross.svg";
import TableActions from "../../../../components/TableActions";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { ProductItemModel } from "../../../../model/product.model";

const ACTION_STYLE =
  "text-sm-regular mb-4 p-2 gap-x-3 flex items-center cursor-pointer";

const AllLocationProducts: FC<IAllLocationProducts> = ({
  transferProduct,
  removeProduct,
  viewProduct,
  type,
  setSelectedItems,
  selectedItems,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.locations.isLocationActionLoading
  );
  const locationProducts = useSelector(
    (state: RootState) => state.locations.locationProducts
  );
  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [product, setProduct] = useState<ProductItemModel | undefined>(
    undefined
  );

  const handleOpenChange = (newOpen: boolean, record: any) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setProduct(record.product);
  };

  const handleMore = (option: "TRANSFER" | "REMOVE") => {
    if (option === "TRANSFER" && transferProduct)
      transferProduct(product as any);
    if (option === "REMOVE" && removeProduct) removeProduct(product as any);
    setOpen(false);
  };

  const getTotalQuantity = (product: any) => {
    return (
      product.po_details?.reduce(
        (sum: any, po: any) => sum + po?.quantity,
        0
      ) || 0
    );
  };

  const columns = [
    {
      title: "Product name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "In stock",
      dataIndex: "inStock",
      key: "inStock",
    },
    {
      title: "Retail price",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <span onClick={(e) => e.stopPropagation()}>
          <Popover
            content={content}
            trigger="click"
            open={open && currentRow === record?.key}
            placement="bottomLeft"
            onOpenChange={(e) => {
              handleOpenChange(e, record);
            }}
          >
            <div className="cursor-pointer">
              <QuicstockMore />
            </div>
          </Popover>
        </span>
      ),
    },
  ];

  const columnsWithReserve = [
    {
      title: "Product name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Reserved",
      dataIndex: "reserved",
      key: "reserved",
    },
    {
      title: "In stock",
      dataIndex: "inStock",
      key: "inStock",
    },
    {
      title: "Retail price",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <span onClick={(e) => e.stopPropagation()}>
          <Popover
            content={content}
            trigger="click"
            open={open && currentRow === record?.key}
            placement="bottomLeft"
            onOpenChange={(e) => {
              handleOpenChange(e, record);
            }}
          >
            <div className="cursor-pointer">
              <QuicstockMore />
            </div>
          </Popover>
        </span>
      ),
    },
  ];

  const content = (
    <div className="w-[210px] table-menu">
      <div className={ACTION_STYLE} onClick={() => handleMore("TRANSFER")}>
        <QuicstockTransfer />
        Transfer
      </div>
      <div
        className={`${ACTION_STYLE} !mb-0`}
        onClick={() => handleMore("REMOVE")}
      >
        <QuicstockFolderCross />
        Remove
      </div>
    </div>
  );

  const data =
    locationProducts &&
    locationProducts.products &&
    locationProducts.products?.length > 0 &&
    locationProducts.products?.map((product, index) => {
      return {
        key: index,
        product_name: product.product_name ? (
          <div className="flex items-center gap-x-2">
            <div className="h-[30px] w-[30px] bg-gray-800 rounded-full"></div>
            {product.product_name}
          </div>
        ) : (
          "---"
        ),
        upc: product?.upc ?? "---",
        reserved: "---",
        inStock: (
          <div className="text-instock-main bg-instock-bg px-2 py-1 text-labels rounded-[24px] text-center">
            In stock
          </div>
        ),
        retail_price: product?.retail_price ? (
          <div>
            {NAIRA}
            {formatCurrency(product?.retail_price.toString())}
          </div>
        ) : (
          "---"
        ),
        quantity: getTotalQuantity(product),
        id: product?.product_id,
        product: product,
      };
    });

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedItems(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleImport = () => {};
  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleImport={handleImport}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
        disableDelete={selectedItems.length === 0}
      />
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={type === "ONLINE" ? columnsWithReserve : columns}
          dataSource={data as any}
          size={"small"}
          className={`d-table ${type === "BULK" ? "add-pointer" : ""}`}
          onRow={(e, item) => {
            return {
              onClick: () => {
                if (type === "BULK") viewProduct && viewProduct(e?.product);
              },
            };
          }}
          pagination={false}
        />
      </div>
    </>
  );
};

export { AllLocationProducts };
