import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import ReactECharts from "echarts-for-react";
import { Select } from "antd";

const option = {
  grid: { left: 10, top: 24, right: 8, bottom: 10, containLabel: true, },
  xAxis: {
    type: "category",
    data: ["Cocacola", "Fanta", "Laptop", "Earbuds", "Slippers"],
    axisLabel: { color: "#666" },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: (value: number) => `₦${value / 1000}k`,
      color: "#666",
    },
  },
  series: [
    {
      type: "bar",
      data: [17000, 8000, 15000, 5000, 10000],
      barWidth: "50%",
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: (params: any) => {
          const colors = [
            "#fdeae5",
            "#e5ebfd",
            "#fde5fb",
            "#eae5fd",
            "#e5fde5",
          ];
          return colors[params.dataIndex];
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
    axisPointer: { type: "shadow" },
  },
};

const TopProducts: FC = () => {
  const [chartOption, setChartOption] = useState<any>({});
  const [valueType, setValueType] = useState<string>("");

  useEffect(() => {
    setChartOption({ ...option });
  }, []);

  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-headline font-medium">Top Products</p>
        <Select
          placeholder={"Select option"}
          value={valueType || null}
          className={"overview-field !w-fit"}
          onChange={(e: any) => {
            setValueType(e);
          }}
          options={[{ value: "THIS_WEEK", label: "This weeks" }]}
        />
      </div>
      <ReactECharts option={chartOption} style={{ height: 400 }} />
    </div>
  );
};

export { TopProducts };
