import React, { FC } from "react";
import "../styles.scss";
import { IFieldName } from "../model";
import { Tooltip } from "antd";
import { ReactComponent as QuicstockInfo } from "../../assets/svgs/quicstock-info.svg";

const FieldName: FC<IFieldName> = ({ name, tooltip, required, className }) => {
  return (
    <div
      className={`text-left text-gray-300 text-sm-regular mb-2 ${className ? className : ""}`}
    >
      <p className="flex">
        <span>{name}</span>
        {tooltip && (
          <span className="ml-1">
            <Tooltip title={tooltip}>
              <QuicstockInfo height={12} style={{ cursor: "pointer" }} />
            </Tooltip>
          </span>
        )}
        {required && <span className="text-secondary-500 ml-1">**</span>}
      </p>
    </div>
  );
};

export default FieldName;
