import React, { useState } from "react";
import "../styles.scss";
import Back from "../../../components/Back";
import { AddCustomPropertyField, AddCustomPropertyProduct } from "./components";
import { ICustomPropertyProduct } from "../model";
import {
  CustomPropertyBaseModel,
  ProductResponseModel,
} from "../../../model/product.model";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { CreateCustomProperty } from "../../../redux/actions/products.action";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";

const NewCustomProperty = () => {
  const dispatch: AppDispatch = useDispatch();
  const [propertyProductItem, setPropertyProductItem] =
    useState<ICustomPropertyProduct>({
      productId: "",
      isGlobal: false,
      poIds: [],
    });

  const handleSubmit = (values: CustomPropertyBaseModel) => {
    const payload: CustomPropertyBaseModel = {
      product_id: propertyProductItem.productId,
      custom_properties: values.custom_properties.map((property) => ({
        property_name: property.property_name,
        property_type: property.property_type,
        property_value:
          property.property_type === "date"
            ? property.property_value.map((item) => ({
                po_numbers: item.po_numbers,
                is_global: item.is_global,
                value: moment(item.value).format("YYYY-MM-DD"),
              }))
            : property.property_type === "number"
              ? property.property_value.map((item) => ({
                  po_numbers: item.po_numbers,
                  is_global: item.is_global,
                  value: parseInt(item.value),
                }))
              : property.property_value,
      })) as any,
    };
    createCustomProperty(payload);
  };

  const createCustomProperty = (values: CustomPropertyBaseModel) => {
    dispatch(CreateCustomProperty(values))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New custom property added"
            message={res?.message}
          />,
          true
        );
        window.history.back();
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Back isPrevious={true} />
        <div>
          <p className="text-x-sm-headline font-medium">
            Create custom property
          </p>
          <p className="text-sm-regular text-gray-300">
            Fill the details below to create a new custom property
          </p>
        </div>
      </div>
      <div className="default-page-height w-full xl:pt-10 pt-5">
        <div className="grid sm:grid-cols-2 grid-cols-1 h-full">
          <div className="xl:px-6 sm:pr-6 border-r border-r-dark-500 h-full">
            <AddCustomPropertyProduct setItem={setPropertyProductItem} />
          </div>
          <div className="xl:pl-6 sm:pl-6 custom-property-section">
            <AddCustomPropertyField
              submit={handleSubmit}
              propertyProductItem={propertyProductItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomProperty;
