import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IProductForm } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input, Upload, Button as Btn } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import { ProductItemModel } from "../../../../model/product.model";
import { formatCurrency } from "../../../../utils/constants";
const { TextArea } = Input;

const ProductForm: FC<IProductForm> = ({ submit, cancel, product }) => {
  const [values, setValues] = useState<ProductItemModel>({
    name: "",
    upc: "",
    brand: "",
    category: "",
    weight: "",
    color: "",
    description: "",
    retail_price: "",
    image: "",
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit(values);
  };

  useEffect(() => {
    if (product) {
      setValues(product);
    }
  }, [product]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className="w-full drawer-content-height">
            <FieldContainer iconPlacementCss={"iconCss"}>
              <Form.Item
                name={"image"}
                help={touched.image && errors.image ? errors.image : ""}
                validateStatus={
                  touched.image && errors.image ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Upload
                      beforeUpload={(file) => {
                        setFieldValue("image", file);
                        return false;
                      }}
                      onChange={(e) => {
                        if (e?.fileList?.length === 0) {
                          setFieldValue("image", null);
                        }
                      }}
                      multiple={false}
                      listType={"picture"}
                      className={"w-full upload"}
                      maxCount={1}
                      accept={"image/*"}
                    >
                      <Btn
                        className={
                          "w-full h-[3rem] flex items-center justify-center rounded-[10px] text-gray-300"
                        }
                      >
                        Upload product image (optional)
                      </Btn>
                    </Upload>
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Product name"} required />
                <Form.Item
                  name={"name"}
                  help={touched.name && errors.name ? errors.name : ""}
                  validateStatus={
                    touched.name && errors.name ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        placeholder="Enter product name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"UPC"} required />
                <Form.Item
                  name={"upc"}
                  help={touched.upc && errors.upc ? errors.upc : ""}
                  validateStatus={
                    touched.upc && errors.upc ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.upc || ""}
                        onChange={(e) => {
                          setFieldValue("upc", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("upc", e.target.value);
                        }}
                        placeholder="Enter UPC"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Brand"} />
                <Form.Item
                  name={"brand"}
                  help={touched.brand && errors.brand ? errors.brand : ""}
                  validateStatus={
                    touched.brand && errors.brand ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.brand || ""}
                        onChange={(e) => {
                          setFieldValue("brand", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("brand", e.target.value);
                        }}
                        placeholder="Enter product brand"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Category"} />
                <Form.Item
                  name={"category"}
                  help={
                    touched.category && errors.category ? errors.category : ""
                  }
                  validateStatus={
                    touched.category && errors.category ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.category || ""}
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("category", e.target.value);
                        }}
                        placeholder="Enter product category"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Weight"} />
                <Form.Item
                  name={"weight"}
                  help={touched.weight && errors.weight ? errors.weight : ""}
                  validateStatus={
                    touched.weight && errors.weight ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.weight || ""}
                        onChange={(e) => {
                          setFieldValue("weight", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("weight", e.target.value);
                        }}
                        placeholder="Enter product weight"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Color"} />
                <Form.Item
                  name={"color"}
                  help={touched.color && errors.color ? errors.color : ""}
                  validateStatus={
                    touched.color && errors.color ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.color || ""}
                        onChange={(e) => {
                          setFieldValue("color", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("color", e.target.value);
                        }}
                        placeholder="Enter product color"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Retail price"} />
                <Form.Item
                  name={"retail_price"}
                  help={
                    touched.retail_price && errors.retail_price
                      ? errors.retail_price
                      : ""
                  }
                  validateStatus={
                    touched.retail_price && errors.retail_price
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.retail_price || ""}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const formattedValue = formatCurrency(inputValue);
                          setFieldValue("retail_price", formattedValue);
                        }}
                        onBlur={(e) => {
                          setFieldValue("retail_price", e.target.value);
                        }}
                        placeholder="Enter retail price"
                        maxLength={25}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Description"} />
                <Form.Item
                  name={"description"}
                  help={
                    touched.description && errors.description
                      ? errors.description
                      : ""
                  }
                  validateStatus={
                    touched.description && errors.description
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <TextArea
                        className={"form-field_input_2"}
                        rows={6}
                        value={values?.description || ""}
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        placeholder="Enter product description"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview changes"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup.string().required("Product name is required"),
  upc: yup.string().required("Product UPC is required"),
  // brand: yup.string().required("Product brand is required"),
  // category: yup.string().required("Product category is required"),
  // weight: yup.number().required("Product weight is required"),
  // color: yup.string().required("Product category is required"),
  // retail_price: yup.string().required("Retail price is required"),
  // description: yup.string().required("Description is required"),
});

export { ProductForm };
