import React, { FC } from "react";
import "../../styles.scss";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { Table } from "antd";
import { RECENT_USER_LOGS } from "../../../../utils/misc/database";

const RecentUserLogs: FC = () => {
  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      ellipsis: true,
    },
  ];

  const data =
    RECENT_USER_LOGS?.length > 0 &&
    RECENT_USER_LOGS?.map((rsl, index) => {
      return {
        key: index,
        userName: rsl.userName ?? "---",
        action: rsl.action ?? "---",
        details: rsl.details ?? "---",
      };
    });

  return (
    <div className="px-4 py-3 border-[1px] border-[#FAFAFA] rounded-[10px]">
      <div className="flex justify-between items-center">
        <p className="text-sm-regular font-medium">Recent User Logs</p>
        <Button
          css={"!px-5 !py-2 !rounded-[6px] !rounded-[6px] !text-labels"}
          state={ButtonState.PRIMARY}
          text={"View all"}
          type={"button"}
        />
      </div>
      <div className="w-full mt-4">
        <Table
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={false}
        />
      </div>
    </div>
  );
};

export { RecentUserLogs };
