/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { VerifyLink } from "../../redux/actions/auths.action";
import { VerifyLinkResponseModel } from "../../model/auth.model";
import openNotification from "../../components/Notification/notification";
import NotificationMessage from "../../components/Notification";

const ValidateToken = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (params?.token) {
      verifyLink(params?.token);
    } else {
      navigate(`/auth`);
    }
  }, [params]);

  const verifyLink = (token: string) => {
    dispatch(VerifyLink(token))
      .then(async (res: VerifyLinkResponseModel) => {
        const accessToken = res.data.token;
        localStorage.setItem("quicstock_app_token", accessToken);
        openNotification(
          <NotificationMessage
            type="success"
            title={res?.message}
            message="Kindly provide your password to complete setup"
          />,
          true
        );
        navigate(`/auth/create-password/${token}`);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  return (
    <div className="w-full global-loader-overlay">
      <Spin size="large" />
    </div>
  );
};

export default ValidateToken;
