import { ThunkAction } from "redux-thunk";
import {
  bulkProductUpload,
  createCustomProperty,
  createProduct,
  createUnit,
  deleteCustomProperty,
  deleteUnit,
  getCustomProperties,
  getCustomProperty,
  getProduct,
  getProductPOs,
  getProducts,
  getProductUnits,
  getRestockLocations,
  getUnits,
  searchProducts,
  setRetailPrice,
  updateCustomProperty,
  updateProduct,
  updateUnit,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { ApiPaginationModel } from "../../model/base.model";
import {
  CustomPropertyBaseModel,
  SellingRetailPriceRequestModel,
  UnitItemModel,
} from "../../model/product.model";

export const Types = {
  CREATE_PRODUCT_START: "CREATE_PRODUCT_START",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_END: "CREATE_PRODUCT_END",

  BULK_PRODUCT_UPLOAD_START: "BULK_PRODUCT_UPLOAD_START",
  BULK_PRODUCT_UPLOAD_SUCCESS: "BULK_PRODUCT_UPLOAD_SUCCESS",
  BULK_PRODUCT_UPLOAD_END: "BULK_PRODUCT_UPLOAD_END",

  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_END: "FETCH_PRODUCTS_END",

  SEARCH_PRODUCTS_START: "SEARCH_PRODUCTS_START",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_END: "SEARCH_PRODUCTS_END",

  FETCH_PRODUCT_START: "FETCH_PRODUCT_START",
  FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_END: "FETCH_PRODUCT_END",

  SET_PRICE_START: "SET_PRICE_START",
  SET_PRICE_SUCCESS: "SET_PRICE_SUCCESS",
  SET_PRICE_END: "SET_PRICE_END",

  CREATE_CUSTOM_PROPERTY_START: "CREATE_CUSTOM_PROPERTY_START",
  CREATE_CUSTOM_PROPERTY_SUCCESS: "CREATE_CUSTOM_PROPERTY_SUCCESS",
  CREATE_CUSTOM_PROPERTY_END: "CREATE_CUSTOM_PROPERTY_END",

  FETCH_CUSTOM_PROPERTIES_START: "FETCH_CUSTOM_PROPERTIES_START",
  FETCH_CUSTOM_PROPERTIES_SUCCESS: "FETCH_CUSTOM_PROPERTIES_SUCCESS",
  FETCH_CUSTOM_PROPERTIES_END: "FETCH_CUSTOM_PROPERTIES_END",

  FETCH_CUSTOM_PROPERTY_START: "FETCH_CUSTOM_PROPERTY_START",
  FETCH_CUSTOM_PROPERTY_SUCCESS: "FETCH_CUSTOM_PROPERTY_SUCCESS",
  FETCH_CUSTOM_PROPERTY_END: "FETCH_CUSTOM_PROPERTY_END",

  UPDATE_CUSTOM_PROPERTY_START: "UPDATE_CUSTOM_PROPERTY_START",
  UPDATE_CUSTOM_PROPERTY_SUCCESS: "UPDATE_CUSTOM_PROPERTY_SUCCESS",
  UPDATE_CUSTOM_PROPERTY_END: "UPDATE_CUSTOM_PROPERTY_END",

  DELETE_CUSTOM_PROPERTY_START: "DELETE_CUSTOM_PROPERTY_START",
  DELETE_CUSTOM_PROPERTY_SUCCESS: "DELETE_CUSTOM_PROPERTY_SUCCESS",
  DELETE_CUSTOM_PROPERTY_END: "DELETE_CUSTOM_PROPERTY_END",

  FETCH_PRODUCT_POS_START: "FETCH_PRODUCT_POS_START",
  FETCH_PRODUCT_POS_SUCCESS: "FETCH_PRODUCT_POS_SUCCESS",
  FETCH_PRODUCT_POS_END: "FETCH_PRODUCT_POS_END",

  FETCH_RESTOCK_LOCATIONS_START: "FETCH_RESTOCK_LOCATIONS_START",
  FETCH_RESTOCK_LOCATIONS_SUCCESS: "FETCH_RESTOCK_LOCATIONS_SUCCESS",
  FETCH_RESTOCK_LOCATIONS_END: "FETCH_RESTOCK_LOCATIONS_END",

  CREATE_UNIT_START: "CREATE_UNIT_START",
  CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
  CREATE_UNIT_END: "CREATE_UNIT_END",

  FETCH_UNITS_START: "FETCH_UNITS_START",
  FETCH_UNITS_SUCCESS: "FETCH_UNITS_SUCCESS",
  FETCH_UNITS_END: "FETCH_UNITS_END",

  UPDATE_UNIT_START: "UPDATE_UNIT_START",
  UPDATE_UNIT_SUCCESS: "UPDATE_UNIT_SUCCESS",
  UPDATE_UNIT_END: "UPDATE_UNIT_END",

  DELETE_UNIT_START: "DELETE_UNIT_START",
  DELETE_UNIT_SUCCESS: "DELETE_UNIT_SUCCESS",
  DELETE_UNIT_END: "DELETE_UNIT_END",

  UPDATE_PRODUCT_START: "UPDATE_PRODUCT_START",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_END: "UPDATE_PRODUCT_END",

  FETCH_PRODUCT_UNITS_START: "FETCH_PRODUCT_UNITS_START",
  FETCH_PRODUCT_UNITS_SUCCESS: "FETCH_PRODUCT_UNITS_SUCCESS",
  FETCH_PRODUCT_UNITS_END: "FETCH_PRODUCT_UNITS_END",
};

export const CreateProduct = (
  formData: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_PRODUCT_START });
    return new Promise((resolve, reject) => {
      createProduct(formData)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_PRODUCT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const BulkProductUpload = (
  formData: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.BULK_PRODUCT_UPLOAD_START });
    return new Promise((resolve, reject) => {
      bulkProductUpload(formData)
        .then(({ data }) => {
          dispatch({ type: Types.BULK_PRODUCT_UPLOAD_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.BULK_PRODUCT_UPLOAD_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchProducts = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCTS_START });
    return new Promise((resolve, reject) => {
      getProducts(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCTS_SUCCESS,
            products: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const SearchProducts = (
  searchText: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SEARCH_PRODUCTS_START });
    return new Promise((resolve, reject) => {
      searchProducts(searchText)
        .then(({ data }) => {
          dispatch({
            type: Types.SEARCH_PRODUCTS_SUCCESS,
            productSearchResult: data?.data?.products ?? [],
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SEARCH_PRODUCTS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};

export const FetchProduct = (
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCT_START });
    return new Promise((resolve, reject) => {
      getProduct(id)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_SUCCESS,
            product: data?.data?.product_details,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const SetRetailPrice = (
  id: string,
  req: SellingRetailPriceRequestModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SET_PRICE_START });
    return new Promise((resolve, reject) => {
      setRetailPrice(id, req)
        .then(({ data }) => {
          dispatch({
            type: Types.SET_PRICE_SUCCESS,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SET_PRICE_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const CreateCustomProperty = (
  req: CustomPropertyBaseModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      createCustomProperty(req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchCustomProperties = (): ThunkAction<
  Promise<any>,
  RootState,
  unknown,
  Action
> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_CUSTOM_PROPERTIES_START });
    return new Promise((resolve, reject) => {
      getCustomProperties()
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTIES_SUCCESS,
            cp: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTIES_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchCustomProperty = (
  productId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      getCustomProperty(productId)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTY_SUCCESS,
            cpDetails: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const UpdateCustomProperty = (
  customPropertyId: string,
  req: CustomPropertyBaseModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      updateCustomProperty(customPropertyId, req)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const DeleteCustomProperty = (
  customPropertyId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      deleteCustomProperty(customPropertyId)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchProductPOs = (
  productId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCT_POS_START });
    return new Promise((resolve, reject) => {
      getProductPOs(productId)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_POS_SUCCESS,
            productPos: data.data?.pos,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_POS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchRestockLocations = (
  productId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_RESTOCK_LOCATIONS_START });
    return new Promise((resolve, reject) => {
      getRestockLocations(productId)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_LOCATIONS_SUCCESS,
            restockLocations: data.data?.locations,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_LOCATIONS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};

export const CreateUnit = (
  req: UnitItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_UNIT_START });
    return new Promise((resolve, reject) => {
      createUnit(req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_UNIT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_UNIT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchUnits = (): ThunkAction<
  Promise<any>,
  RootState,
  unknown,
  Action
> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_UNITS_START });
    return new Promise((resolve, reject) => {
      getUnits()
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_UNITS_SUCCESS,
            uoms: data?.data?.uoms,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_UNITS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const UpdateUnit = (
  unitId: string,
  req: UnitItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_UNIT_START });
    return new Promise((resolve, reject) => {
      updateUnit(unitId, req)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_UNIT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_UNIT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const DeleteUnit = (
  unitId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_UNIT_START });
    return new Promise((resolve, reject) => {
      deleteUnit(unitId)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_UNIT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_UNIT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const UpdateProduct = (
  formData: any,
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_PRODUCT_START });
    return new Promise((resolve, reject) => {
      updateProduct(formData, id)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_PRODUCT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};

export const FetchProductUnit = (
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCT_UNITS_START });
    return new Promise((resolve, reject) => {
      getProductUnits(id)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_UNITS_SUCCESS,
            uom: data?.data?.uom,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_UNITS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data?.message || "An error occured");
        });
    });
  };
};
