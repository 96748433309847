import React, { FC } from "react";
import "../../styles.scss";
import { IEditSellingDetails } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { SellingDetailsPreview } from "./SellingDetailsPreview";
import { SellingDetailsForm } from "./SellingDetailsForm";

const EditSellingDetails: FC<IEditSellingDetails> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  retailPrice,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Pricing details"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <SellingDetailsForm submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <SellingDetailsPreview
            retailPrice={retailPrice}
            goBack={goBack}
            complete={complete}
          />
        </div>
      </div>
    </div>
  );
};

export { EditSellingDetails };
