import React, { FC } from "react";
import "../../styles.scss";
import { IProductLocation } from "../../model";

const ProductLocation: FC<IProductLocation> = ({ title, locations }) => {
  return (
    <div className="p-4 border-[0.5px] border-[#E4E7EC] rounded-[12px] mb-4">
      <p className="xl:text-sm-regular text-labels mb-6">{title}</p>
      {locations?.map((l, index) => (
        <div
          key={l?.id}
          className={`xl:text-sm-regular text-labels flex justify-between ${index < locations.length - 1 ? "mb-4" : ""}`}
        >
          <p className="text-[#667185]">{l?.name}</p>
          <p>{l?.quantity}</p>
        </div>
      ))}
    </div>
  );
};

export { ProductLocation };
